import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";





export const getStockReport = createAsyncThunk("/getStockReport",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList("/Stockmanagement/stockreport/", payload);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);


