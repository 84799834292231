import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Avatar, Button, Typography, Image, Space, Empty  } from "antd";
import PageTitle from "../../layout/components/content/page-title";
import { sidebarEnable } from "../../store/customise/customise";
import { RiEqualizerLine } from "react-icons/ri";
import EmptyImage from "../../assets/images/app/empty-list.svg";
import open from "../../assets/images/opening.svg";
import close from "../../assets/images/closing.svg";
import dispatchImg from "../../assets/images/dispatch.svg";
import pending from "../../assets/images/pending.svg";
import qty from "../../assets/images/qty.svg";
import orders from "../../assets/images/orders.svg";

import { RiArrowRightSLine } from "react-icons/ri";
import Filter from "./Filter";
import Chart from "react-apexcharts";
import {
  getStockDashboard,
  getWeakDashboard,
  // getTodayDashboard
} from "../../store/sideMenu/dashboard/dashboardActions";
import moment from "moment";
import { getWarehouseList } from "../../store/mini/miniActions";
import StockFilter from "./StockFilter";
import { isDrawerVisible, isDrawerVisible2, clearGraphData } from "../../store/sideMenu/dashboard/dashboardSlice";
import { color } from "framer-motion";

const styles = {
  openingAndClosingFontSize: {
    fontSize: "12px",
  },
};

export default function Home() {
  const dispatch = useDispatch();
  const { Title, Text } = Typography;
  let pageParams = useSelector((state) => state.dashboard.pageParams);
  let pageGraphParams = useSelector((state) => state.dashboard.pageGraphParams);
  let warehouseParams = useSelector((state) => state.mini.warehouseParams);
  const warehoueList = useSelector((state) => state.mini.warehoueList);
  const dashboardProduct = useSelector((state) => state.dashboard.dashboardProduct);
  const weakDashboardData = useSelector((state) => state.dashboard.weakDashboardData);
  const weakDashboardCategory = useSelector((state) => state.dashboard.weakDashboardCategory);

  
  const weakData = useSelector((state) => state.dashboard.weakData);

  const warehouseName = warehoueList.find(item => item.id === pageParams?.warehouse);

  useEffect(() => {
    if (!isMobile) {
      dispatch(sidebarEnable(true));
    }
    return () => {
      dispatch(sidebarEnable(false));
    };
  }, []);


  


  // const fetchData = () => {
  //   dispatch(
  //     getWeakDashboard({
  //       ...pageGraphParams,
  //       start_date: moment(),
  //       end_date: moment(),
  //     })
  //   );
  // };

  const fetchData = () => {
    let { start_date, end_date, dateType } = pageGraphParams;
    if (dateType !== "custom") {
      start_date = moment().startOf(dateType);
      end_date = moment().endOf(dateType);
      dispatch(
        getWeakDashboard({
          ...pageGraphParams,
          start_date,
          end_date,
        })
      );
    } else {
      dispatch(
        getWeakDashboard({
          ...pageGraphParams,
          start_date: moment(pageGraphParams.start_date),
          end_date: moment(pageGraphParams.end_date),
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData(); // Call the function every 10 seconds
    }, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [pageGraphParams]);

 
 
  // const [chartData, setChartData] = useState({
  //   series: [
  //     { name: "Orders Placed", data: [] },
  //     { name: "Dispatch Orders", data: [] },
  //     { name: "Pending Dispatch", data: [] },
  //   ],
  //   options: {
  //     chart: {
  //       type: "bar",
  //       toolbar: { show: false },
  //       zoom: { enabled: false },
  //     },
  //     plotOptions: {
  //       bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded",
  //         dataLabels: {
  //           position: 'top', // top, center, bottom
  //         },
  //        },
        
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function (val) {
  //         return val ;
  //       },
  //       offsetY: -20,
  //       style: {
  //         fontSize: "14px",
  //         colors: ["#304758"],
  //       },
  //     },
  //     stroke: { show: true, width: 2, colors: ["transparent"] },
  //     xaxis: {
  //       categories: [],
  //       tooltip: {
  //         enabled: true,
  //       },
  //       labels: { style: { colors: ["#636E72"], fontSize: "12px" } },
  //     },
  //     yaxis: { labels: { style: { colors: ["#636E72"], fontSize: "12px" } } },
  //     grid: { borderColor: "#DFE6E9" },
  //     legend: {
  //       position: "top",
  //       horizontalAlign: "center",
  //       offsetX: 0,
  //       markers: { radius: 50 },
  //     },
  //     colors: ["#0063F7", "#00F7BF", "#FF4560"],
  //   },
  // });
  //   // Update chart data when weakDashboardData or weakData changes
  // useEffect(() => {
  //   if (weakDashboardData && weakData) {
  //     setChartData((prevState) => ({
  //       ...prevState,
  //       series: weakDashboardData,
  //       options: {
  //         ...prevState.options,
  //         xaxis: { ...prevState.options.xaxis, categories: weakData },
  //       },
  //     }));
  //   }
  // }, [weakDashboardData, weakData]);

  const iconMap = {
    open: open,
    close: close,
    dispatchImg: dispatchImg,
    pending: pending,
    qty: qty,
    orders: orders,
  };



  
  useEffect(() => {
    dispatch(
      getWarehouseList({
        page: 1,
        search: "",
        page_size: 10,
      })
    );
    dispatch(clearGraphData())
  }, []);
  


  // warehoues wise products
  useEffect(() => {
    if (warehoueList.length != 0) {
      dispatch(getStockDashboard({
        warehouse: warehoueList.find(e => e.id)?.id
      }));
    }
  }, [warehoueList])






 

  // useEffect(() => {
  //   if (warehoueList.length != 0) {
  //     dispatch(
  //       getOpeningStockByDate({
  //         date: moment().format("YYYY-MM-DD"),
  //         warehouse: warehoueList.find(e => e.id)?.id,
  //       })
  //     );

  //     dispatch(
  //       getclosingStockByDate({
  //         date: moment().format("YYYY-MM-DD"),
  //         warehouse: warehoueList.find(e => e.id)?.id,
  //       })
  //     );
  //   }
  // }, [warehoueList]);

  // const data = productsList?.map((product) => {
  //   console.log(product)
  //   // const openStock = openingStockCount[0]?.openingstock_items?.find(item => item?.product?.id == product?.id);

  //   // const closedStock = closingStockCount[0]?.closingstock_items?.find(item => item?.product?.id == product?.id);

  //   const openStock = openingStockCount.find(item => item?.product?.id == product?.id);

  //   const closedStock = closingStockCount.find(item => item?.product?.id == product?.id);

  //   return {
  //     ...product,
  //     openStock,
  //     closedStock
  //   }
  // })

  // const [data] = useState({
  //   series: [
  //     {
  //       name: "Orders Placed",
  //       data: [400, 450, 500, 450, 400, 450, 400],
  //     },
  //     {
  //       name: "Dispatch Orders",
  //       data: [50, 100, 150, 100, 50, 100, 50],
  //     },
  //     {
  //       name: "Pending Dispatch",
  //       data: [20, 40, 60, 40, 20, 40, 20],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "bar",
  //       toolbar: {
  //         show: false,
  //       },
  //       zoom: {
  //         enabled: false,
  //       },
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         borderRadius: 10,
  //         columnWidth: "45%",
  //         endingShape: "rounded",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["transparent"],
  //     },
  //     xaxis: {
  //       categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  //       labels: {
  //         style: {
  //           colors: ["#636E72"],
  //           fontSize: "10px",
  //         },
  //       },
  //     },
  //     yaxis: {
  //       // title: {
  //       //   text: "Count",
  //       // },
  //       labels: {
  //         style: {
  //           colors: ["#636E72"],
  //           fontSize: "10px",
  //         },
  //       },
  //     },
  //     grid: {
  //       borderColor: "#DFE6E9",
  //     },
  //     legend: {
  //       position: "top",
  //       horizontalAlign: "right",
  //       offsetX: 0,
  //       markers: {
  //         radius: 12,
  //       },
  //     },
  //     colors: ["#0063F7", "#00F7BF", "#FF4560"],
  //   },
  // });


  return (
    <Row>
      <Col span={24}>
        <Row className="hp-ecommerce-app-header hp-mb-10 hp-mt-10">
          <Col span={24} xs={22} sm={22}>
            <Title level={5}>Dashboard</Title>
          </Col>

          <Col span={24} xs={2} sm={2}>
            <RiEqualizerLine
              onClick={() => {
                dispatch(isDrawerVisible(true));
              }}
              className="remix-icon hp-text-color-black-100 hp-text-color-dark-0"
              size={24}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Card className="hp-warning-shadow">
          {/* <div id="chart">
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={350}
            />
          </div> */}

           {/* <div id="chart">
              <Chart
                options={{
                  chart: {
                    fontFamily: "Manrope, sans-serif",
                    type: "line",

                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  stroke: {
                    curve: "smooth",
                    lineCap: "round",
                    width: 2,
                  },
                  colors: [
                    "#148DE6",
                    "#CB66DA",
                    "#5DC4A1",
                    "#B96A6F",
                    "#7B3DB9",
                    "#800080",
                  ],
                  labels: {
                    style: {
                      fontSize: "14px",
                    },
                  },

                  dataLabels: {
                    enabled: true,
                  },

                  grid: {
                    borderColor: "#DFE6E9",
                    row: {
                      opacity: 0.5,
                    },
                  },

                  markers: {
                    strokeWidth: 0,
                    size: 4,
                    colors: [
                      "#148DE6",
                      "#CB66DA",
                      "#5DC4A1",
                      "#B96A6F",
                      "#7B3DB9",
                      "#800080",
                    ],
                    hover: {
                      sizeOffset: 1,
                    },
                  },
                  xaxis: {
                    axisTicks: {
                      show: false,
                      borderType: "solid",
                      color: "#78909C",
                      height: 6,
                      offsetX: 0,
                      offsetY: 0,
                    },

                    tickPlacement: "between",
                    labels: {
                      style: {
                        colors: ["636E72"],
                        fontSize: "14px",
                      },
                    },
                    categories: weakDashboardCategory?.map((d) => d),
                  },
                  responsive: [
                    {
                      breakpoint: 426,
                      options: {
                        legend: {
                          itemMargin: {
                            horizontal: 16,
                            vertical: 8,
                          },
                        },
                      },
                    },
                  ],
                  legend: {
                    horizontalAlign: "right",
                    offsetX: 40,
                    position: "top",
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: ["636E72"],
                        fontSize: "14px",
                      },
                      formatter: (value) => {
                        return value;
                      },
                    },
                    min: 50,
                    max: 15000,
                    tickAmount: 5,
                  },
                }}
                series={weakDashboardData?.map((d) => d)}
                type="line"
                height={350}
                legend="legend"
              />
            </div> */}

          <div id="chart">
            <Chart
              height={350}
               type="bar"
              legend="legend"
              options={{
                chart: {
                  id: "chart",
                  fontFamily: "Manrope, sans-serif",
                  type: "bar",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                
                colors: [
                  "#148DE6",
                  "#CB66DA",
                  "#5DC4A1",
                  "#B96A6F",
                  "#7B3DB9",
                  "#800080",
                ],
                labels: {
                  style: {
                    fontSize: "14px",
                  },
                },

                dataLabels: {
                  enabled: true,
                  // offsetY: 20,
                  style: {
                    fontSize: '16px',
                    colors: ["#000"],
                    textOrientation: 'horizontal',
                  },
                  offsetX: 0, // customize this value
                  offsetY: 150, // customize this value
                },
                markers: {
                    strokeWidth: 0,
                    size: 4,
                    colors: [
                      "#148DE6",
                      "#CB66DA",
                      "#5DC4A1",
                      "#B96A6F",
                      "#7B3DB9",
                      "#800080",
                    ],
                    hover: {
                      sizeOffset: 1,
                    },
                  },
                grid: {
                  borderColor: "#DFE6E9",
                  row: {
                    opacity: 0.5,
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: "60%",
                    endingShape: "rounded",
                  },
                  dataLabels: {
                    position: 'top', // top, center, bottom
                    color: '#000'
                  },
                  colors: {
                    backgroundBarColors: ["#0063F7", "#00F7BF"],
                  },
                },

                stroke: {
                  show: true,
                  width: 2,
                  colors: ["transparent"],
                },
                xaxis: {
                  axisTicks: {
                    show: false,
                    borderType: "solid",
                    color: "#78909C",
                    height: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  tickPlacement: "between",
                  labels: {
                    style: {
                      colors: ["636E72"],
                      fontSize: "14px",
                    },
                  },
                  categories: weakDashboardCategory?.map((d) => d),
                },
                responsive: [
                  {
                    breakpoint: 426,
                    options: {
                      legend: {
                        itemMargin: {
                          horizontal: 16,
                          vertical: 8,
                        },
                      },
                    },
                  },
                ],
                legend: {
                  horizontalAlign: "right",
                  offsetX: 40,
                  position: "top",
                  markers: {
                    radius: 12,
                  },
                },
                yaxis: {
                  labels: {
                    style: {
                      colors: ["636E72"],
                      fontSize: "14px",
                    },
                    formatter: (value) => {
                      return value;
                    },
                  },
                },
              }}
              series={weakDashboardData?.map((d) => d)}
             
            />
          </div>
        </Card>
      </Col>

      <Col span={24}>
        <Row className="hp-ecommerce-app-header hp-mb-10 hp-mt-10">
          <Col span={24} xs={22} sm={22}>
            <Title level={5}>{warehouseName?.name}</Title>
          </Col>

          <Col span={24} xs={2} sm={2}>
            <RiEqualizerLine
              onClick={() => {
                dispatch(isDrawerVisible2(true));
              }}
              className="remix-icon hp-text-color-black-100 hp-text-color-dark-0"
              size={24}
            />
          </Col>
        </Row>
      </Col>

      <Col
        span={24}
        style={{
          height: "150vh",
          overflow: "scroll",
        }}
      >
        {dashboardProduct.length !== 0 ? (
          <>
            {dashboardProduct?.map((item, index) => {
              return (
                <Card bodyStyle={{ padding: "10px" }} key={index}>
                  <Title level={5}>{item?.product?.name}</Title>
                  {/* <Text className="hp-font-weight-600 hp-text-color-danger-2">
                {item.warehouse?.name}
              </Text> */}
                  <div
                    style={{
                      background: "#fff7ef",
                      padding: "10px",
                    }}
                  >
                    <Row gutter={[12, 0]}>
                      {[
                        {
                          label: "Opening Stock",
                          value: item.opening_stock,
                          icon: "open",
                        },
                        {
                          label: "Orders Placed",
                          value: item.order_placed,
                          icon: "orders",
                        },
                        {
                          label: "Dispatch Orders",
                          value: item.dispatch,
                          icon: "dispatchImg",
                        },
                        {
                          label: "Production Qty",
                          value: item.production_quantity,
                          icon: "qty",
                        },
                        {
                          label: "Pending Dispatch",
                          value: item.pending_dispatch,
                          icon: "pending",
                        },
                        {
                          label: "Closing Stock",
                          value: item.closing_stock,
                          icon: "close",
                        },
                      ].map((field, idx) => (
                        <Col
                          key={idx}
                          span={12}
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            <Image src={iconMap[field.icon]} />
                          </div>
                          <div>
                            <Text
                              style={styles.openingAndClosingFontSize}
                              align="left"
                              className="hp-font-weight-600 hp-mb-0"
                            >
                              {field.label}
                            </Text>
                            <p
                              align="left"
                              className="hp-font-weight-500 hp-mt-0 hp-mb-0"
                              style={styles.openingAndClosingFontSize}
                            >
                              {field.value}
                            </p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card>
              );
            })}
          </>
        ) : (
          <Card>
            <Empty
              className="hp-m-32"
              image={EmptyImage}
              imageStyle={{
                height: 160,
              }}
              description={<h5>Your Stock list is empty</h5>}
            ></Empty>
          </Card>
        )}
      </Col>
      <Filter />
      <StockFilter />
    </Row>
    // <Row gutter={[12, 0]}>
    //   <Col span={24}>
    //     <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10">
    //       <Col span={24} xs={20} sm={20}>
    //         <PageTitle pageTitle="Dashboard" goBack={false} />
    //       </Col>
    //       <Col span={24} xs={4} sm={4} className="hp-mt-10">
    //         <Button
    //           onClick={() => {
    //             dispatch(isDrawerVisible(true));
    //           }}
    //           className="hp-w-100 hp-p-10 "
    //           icon={<RiEqualizerLine />}
    //         ></Button>
    //       </Col>
    //       <Col span={24}>
    //         <Text
    //           style={{
    //             ...styles.openingAndClosingFontSize,
    //             paddingLeft: "5px",
    //           }}
    //           align="left"
    //           className="hp-font-weight-600 hp-mb-0"
    //         >
    //           <b>{pageParams.date}</b>

    //         </Text>
    //       </Col>
    //     </Row>

    //     <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10">
    //       <Col span={24} xs={24} sm={24} style={{
    //     height: "300vh",
    //     overflow: "scroll"
    //   }}>
    //         {data?.map((item, index) => (
    //           <Card
    //             bodyStyle={{ padding: "1rem 20px" }}
    //             className="hp-mb-10"
    //             key={index}
    //           >
    //             <Text className="hp-font-weight-600">{item?.name}</Text>
    //             <Row gutter={[24, 12]}>
    //               <Col
    //                 span={12}
    //                 style={{
    //                   background: "#fff7ef",
    //                   padding: "10px",
    //                   display: "flex",
    //                   alignItems: "center",
    //                 }}
    //               >
    //                 <div style={{ marginRight: "10px" }}>
    //                   <Image src={open} />
    //                 </div>
    //                 <div>
    //                   <Text
    //                     style={styles.openingAndClosingFontSize}
    //                     align="left"
    //                     className="hp-font-weight-600 hp-mb-0"
    //                   >
    //                     Opening Stock
    //                   </Text>
    //                   <p
    //                     align="left"
    //                     className="hp-font-weight-500 hp-mt-0 hp-mb-0"
    //                     style={styles.openingAndClosingFontSize}
    //                   >
    //                     {item?.openStock?.quantity
    //                       ? item?.openStock?.quantity
    //                       : 0}
    //                   </p>
    //                 </div>
    //               </Col>

    //               <Col
    //                 span={11}
    //                 style={{
    //                   background: "#fff7ef",
    //                   padding: "10px",
    //                   display: "flex",
    //                   alignItems: "center",
    //                 }}
    //               >
    //                 <div style={{ marginRight: "10px" }}>
    //                   <Image src={close} />
    //                 </div>
    //                 <div>
    //                   <Text
    //                     align="left"
    //                     className="hp-font-weight-600 hp-mb-0"
    //                     style={styles.openingAndClosingFontSize}
    //                   >
    //                     Closing Stock
    //                   </Text>

    //                   <p
    //                     align="left"
    //                     className="hp-font-weight-500 hp-mt-0 hp-mb-0"
    //                     style={styles.openingAndClosingFontSize}
    //                   >
    //                     {item?.closedStock?.quantity
    //                       ? item?.closedStock?.quantity
    //                       : 0}
    //                   </p>
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Card>
    //         ))}
    //       </Col>
    //     </Row>
    //   </Col>
    //   <Filter />
    // </Row>
  );
}
