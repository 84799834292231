import { configureStore, combineReducers } from "@reduxjs/toolkit";
import customiseReducer from "./customise/customise";
import loginSlice from "./auth/login/loginSlice";
import miniSlice from "./mini/miniSlice";
import systemSlice from "./system/systemSlice";
// import deleteUserSlice from "./auth/deleteAccount/deleteUserSlice";
import Masters from "./Masters/masters";
import manageUserSlice from "./settings/ManageUsers/manageUserSlice";
import manageProfileSlice from "./settings/ManageProfile/profileSlice";
import manageCompanySlice from "./settings/ManageCompany/manageCompanySlice";
import importExportSlice from "./settings/ImportExport/importExportSlice";
import appsettingsSlice from "./settings/AppSettings/appsettingsSlice";
import notificationSlice from "./notifications/notificationSlice";
import attendanceSlice from "./sideMenu/attendance/attendanceSlice";
import reportsSlice from "./reports/reportsSlice";
import userProfileSlice from "./userProfile/profileSlice";
import opeaningStockSlice from "./sideMenu/opeaningstock/opeaningStockSlice";
import closingstockSlice from "./sideMenu/closingstock/closingstockSlice";
import stockReportSlice from "./sideMenu/stockReport/stockReportSlice";
import dashboardSlice from "./sideMenu/dashboard/dashboardSlice";
import stockManagementSlice from "./sideMenu/stockManagement/stockManagement.slice";

// const Masters = combineReducers({
//   branchData: branchSlice,
//   hpsHouseData: hpsHouseSlice,
//   professionalcategoryData: professionalCategorySlice,
//   zonesData: zoneSlice,
//   wing: wingSlice,
// });

export const store = configureStore({
  reducer: {
    customise: customiseReducer,
    login: loginSlice,
    mini: miniSlice,
    reports: reportsSlice,
    system: systemSlice,
    userProfile: userProfileSlice,
    masters: Masters,
    manageUsers: manageUserSlice,
    manageProfile: manageProfileSlice,
    manageCompany: manageCompanySlice,
    importExport: importExportSlice,
    appSettings: appsettingsSlice,
    attendance: attendanceSlice,
    opeaningstock: opeaningStockSlice,
    closingstock: closingstockSlice,
    stockReport: stockReportSlice,
    dashboard: dashboardSlice,
    stockManagement: stockManagementSlice
  },
});
