import { createSlice } from "@reduxjs/toolkit";

import {
	getStockManagementList,
	getStockManagementById,
	getCurrentStock,
	getProductList,
	postStockManagement,
	postOpeningStock,
	getYestClosingStock,
	getOpeaningStockListByWareHouse,
} from "./stockManagement.actions";
import moment from "moment";

const stockManagementSlice = createSlice({
	name: "stockManagement",
	initialState: {
		loginError: "aaa",
		message: null,
		loading: false,
		apiError: {},
		listCount: 0,
		drawer: false,
		rowdata: {},
		StockList: [],
		stockParams: {
			start_date: "",
      		end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		finalData: [],
		selectedData: {},

		RejectMode: false,
		filterStatus: false,
		rejectReasonObjData: {},

		productList: [],
		productParams: {
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		productCount: 0,
		filterParamsData: {},
		closingStockList: {},
		openStockList: [],
		activeStock: [],
		closingStock: [],
	},
	reducers: {
		clearParams: (state, action) => {
			return {
				...state,
				stockParams: {
					start_date: "",
					  end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
				selectedData:{},
			}
		},
		changeWarehouseParams: (state, action) => {
			state.warehouseParams = action.payload;
		},

		setStockParams: (state, action) => {
			return {
				...state,
				stockParams: action.payload,
			};
		},
		isDrawerVisible: (state, action) => {
			state.drawer = action.payload;
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				stockParams: {
					...state.stockParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		setFilterParams: (state, action) => {
			return {
				...state,
				filterParamsData: {
					...state.filterParamsData,
					...action.payload,
				},
			};
		},
		clearOpeiningStock: (state, action) => {
			return {
				...state,
				loading: true,
				stockParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
				productList: [],
				// selectedData: {},
			};
		},
		changeParams: (state, action) => {
			state.stockParams = action.payload;
		},

		stockInputChangeValue: (state, action) => {
			var selectedStockItem = state.productList.map((item) => {
				if (item.id == action.payload.item.id) {
					return {
						...item,
						[action.payload.key]: action.payload.value,
					};
				}
				return item;
			});
			return {
				...state,
				productList: selectedStockItem,
			};
		},

		InputChangeValue: (state, action) => {
			const { key, value, productId, warehouseId } = action.payload;

			const updatedFinalData = state.finalData.map((product) => {
				if (product.id === productId) {
					return {
						...product,
						warehouses: product.warehouses.map((warehouse) => {
							if (warehouse.id === warehouseId) {
								const obj = {
									...warehouse,
									[key]: value,
								};
								const pending_dispatch =
									parseFloat(obj.order_placed) -
									parseFloat(obj.dispatch);
								const closing_stock =
									parseFloat(obj.opening_stock) -
									parseFloat(obj.dispatch) +
									parseFloat(obj.production_quantity);
								return {
									...obj,
									pending_dispatch,
									closing_stock,
								};
							}
							return warehouse;
						}),
					};
				}
				return product;
			});

			return {
				...state,
				finalData: updatedFinalData,
				// selectedData: {
				//   ...state.selectedData,
				//   [key]: value,
				// },
			};

			// const updatedFinalData = state.finalData.map((product) => {
			//   return {
			//     ...product,
			//     warehouses: product.warehouses.map((warehouse) => {
			//       if (
			//         `${product.id}_${warehouse.id}` ===
			//         action.payload.key.split('_').slice(1).join('_')
			//       ) {
			//         return {
			//           ...warehouse,
			//           [action.payload.key.split('_')[0]]: action.payload.value,
			//         };
			//       }
			//       return warehouse;
			//     }),
			//   };
			// });

			// return {
			//   ...state,
			//   finalData: updatedFinalData,
			//   selectedData: {
			//     ...state.selectedData,
			//     [action.payload.key]: action.payload.value,
			//   },
			// };
		},

		setSelectedData: (state, action) => {
			// console.log("setSelectedData", action.payload)
			return {
				...state,
				finalData: action.payload,
				// selectedData: action.payload.reduce((acc, product) => {
				//   product.warehouses.forEach((warehouse) => {
				//     acc[`opening_stock_${product.id}_${warehouse.id}`] =
				//       warehouse.opening_stock || 0;
				//     acc[`dispatch_${product.id}_${warehouse.id}`] = warehouse.dispatch || 0;
				//     acc[`order_placed_${product.id}_${warehouse.id}`] =
				//       warehouse.order_placed || 0;
				//     acc[`production_quantity_${product.id}_${warehouse.id}`] =
				//       warehouse.production_quantity || 0;
				//     acc[`pending_dispatch_${product.id}_${warehouse.id}`] =
				//       warehouse.pending_dispatch || 0;
				//     acc[`closing_stock_${product.id}_${warehouse.id}`] =
				//       warehouse.closing_stock || 0;
				//   });
				//   return acc;
				// }, {}),
			};
		},
	},

	extraReducers: (builder) => {
		builder
			// stock management List
			.addCase(getStockManagementList.pending, (state, action) => {
				state.status = "getStockManagementList loading";
				state.loading = true;
			})
			.addCase(getStockManagementList.fulfilled, (state, action) => {
				state.status = "getStockManagementList succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.response.count / state.stockParams.page_size
				);
				state.listCount = action.payload.response.count;
				state.StockList = action.payload.response.results;
				// state.openStockList = action.payload.response.results;
				state.stockParams = {
					...state.stockParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getStockManagementList.rejected, (state, action) => {
				state.status = "getStockManagementList failed";
				state.loading = false;
				state.error = action.error.message;
			})

			// get by id
			.addCase(getStockManagementById.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(getStockManagementById.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.selectedData = response;
			})
			.addCase(getStockManagementById.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// stock current data
			.addCase(getCurrentStock.pending, (state, action) => {
				state.status = "getCurrentStock loading";
				state.loading = true;
			})
			.addCase(getCurrentStock.fulfilled, (state, action) => {
				state.status = "getCurrentStock succeeded";
				state.loading = false;
				state.activeStock = action.payload.response.active_records;
				state.closingStock = action.payload.response.closing_records;
			})
			.addCase(getCurrentStock.rejected, (state, action) => {
				state.status = "getCurrentStock failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// products
			.addCase(getProductList.pending, (state, action) => {
				state.status = "getProductList loading";
				state.loading = true;
			})
			.addCase(getProductList.fulfilled, (state, action) => {
				state.status = "getProductList succeeded";
				state.loading = false;
				// state.productCount = action.payload.response.count;
				state.productList = action.payload.response;
			})
			.addCase(getProductList.rejected, (state, action) => {
				state.status = "getProductList failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// post stock
			.addCase(postStockManagement.pending, (state, action) => {
				state.status = "postStockManagement loading";
				state.loading = true;
			})
			.addCase(postStockManagement.fulfilled, (state, action) => {
				state.status = "postStockManagement succeeded";
				state.loading = false;
			})
			.addCase(postStockManagement.rejected, (state, action) => {
				state.status = "postStockManagement failed";
				state.loading = false;
				state.error = action.error.message;
			})

			// old code
			.addCase(
				getOpeaningStockListByWareHouse.pending,
				(state, action) => {
					state.status = "getOpeaningStockListByWareHouse loading";
					state.loading = true;
				}
			)
			.addCase(
				getOpeaningStockListByWareHouse.fulfilled,
				(state, action) => {
					console.log("action.payload", action.payload);
					state.status = "getOpeaningStockListByWareHouse succeeded";
					state.loading = false;
					// var noofpages = Math.ceil(
					//   action.payload.response.count / state.opeaningStockParams.page_size
					// );
					// state.listCount = action.payload.response.count;
					// state.StockList = action.payload.response.results;
					state.openStockList = action.payload.response;
					// state.opeaningStockParams = { ...state.opeaningStockParams, no_of_pages: noofpages, };
				}
			)
			.addCase(
				getOpeaningStockListByWareHouse.rejected,
				(state, action) => {
					state.status = "getOpeaningStockListByWareHouse failed";
					state.loading = false;
					state.error = action.error.message;
				}
			)

			// post opening stock
			.addCase(postOpeningStock.pending, (state, action) => {
				state.status = "postOpeningStock loading";
				state.loading = true;
			})
			.addCase(postOpeningStock.fulfilled, (state, action) => {
				state.status = "postOpeningStock succeeded";
				state.loading = false;
			})
			.addCase(postOpeningStock.rejected, (state, action) => {
				state.status = "postOpeningStock failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getYestClosingStock.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(getYestClosingStock.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.closingStockList = response;
			})
			.addCase(getYestClosingStock.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const {
	setStockParams,
	isDrawerVisible,
	clearOpeiningStock,
	changeParams,
	setSelectedData,
	changeWarehouseParams,
	InputChangeValue,
	stockInputChangeValue,
	setFilterParams,
	FilterInputChangeValue,
	clearParams
} = stockManagementSlice.actions;
export default stockManagementSlice.reducer;
