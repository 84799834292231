import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {getMiniStates, getUserPermissions, getWarehouseList, getCategoryList, getMiniUserList } from "./miniActions";
const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],

    // Params
    warehoueList: [],
    warehouseParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUserList:[],
    miniUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    categoryList: [],
    categoryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        warehouseParams: action.payload,
      };
    },
    getMiniStateClearData: (state) => {
      return {
        ...state,
        getWarehouseList: [],
      };
    },
    
    clearWarehouse: (state, action) => {
      return {
        ...state,
        warehoueList: [],
        warehouseParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions.pending, (state, action) => {
        state.status = "getUserPermissions loading";
        state.loading = true;
      })
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        state.status = "getUserPermissions succeeded";
        state.loading = false;
        state.userAccessList = action.payload.response;
      })
      .addCase(getUserPermissions.rejected, (state, action) => {
        state.status = "getUserPermissions failed";
        state.loading = false;
        state.error = action.error.message;
      })
     

      // warehouse list
      .addCase(getWarehouseList.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getWarehouseList.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.warehoueList = response.results;
      })
      .addCase(getWarehouseList.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

       // mini user list
       .addCase(getMiniUserList.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getMiniUserList.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.miniUserList = response.results;
      })
      .addCase(getMiniUserList.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // Cate4gory List 
      .addCase(getCategoryList.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.categoryList = response.results;
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })


      // .addCase(getWarehouseList.pending, (state, action) => {
      //   state.status = "getWarehouseList loading";
      //   if (state.warehouseParams?.page == 1) {
      //     state.loading = true;
      //     state.warehoueList = [];
      //   } else {
      //     state.loading = true;
      //   }
      // })
      // .addCase(getWarehouseList.fulfilled, (state, action) => {
      //   state.status = "getWarehouseList succeeded";
      //   var response = action.payload;
      //   var list = [];
      //   if (state.warehouseParams?.page == 1) {
      //     list = response.results;
      //   } else {
      //     list = [...state.warehoueList, ...response.results];
      //   }
      //   var noofpages = Math.ceil(
      //     action.payload.count / state.warehouseParams?.page_size
      //   );
      //   state.warehoueList = list;
      //   state.loading = false;
      //   state.warehouseParams = {
      //     ...state.warehouseParams,
      //     no_of_pages: noofpages,
      //   };
      // })
      // .addCase(getWarehouseList.rejected, (state, action) => {
      //   state.status = "getWarehouseList failed";
      //   state.loading = false;
      //   state.error = action.error.message;
      // })

     
  },
});

// Action creators are generated for each case reducer function
export const {
  setMiniStatesParams,
  getMiniStateClearData,
  clearWarehouse,
  apiError,
} = miniSlice.actions;

export default miniSlice.reducer;
