import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";

export const getclosingStockList = createAsyncThunk(
  "/getclosingStockList",
  async (payload) => {
    var data = payload;
    var params = {};

    for (const k in data) {
      if (Object.hasOwnProperty.call(data, k)) {
        if (k === "currentSort" || k === "sortOrder") {
          params.ordering = data.sortOrder + data.currentSort;
        } else {
          if (data[k] !== "" && k !== null && k != "no_of_pages") {
            if (k == "start_date") {
              params[k] = moment(data[k]).format("YYYY-MM-DD");
            } else if (k == "end_date") {
              params[k] = moment(data[k]).format("YYYY-MM-DD");
            } else {
              params[k] = data[k];
            }
          }
        }
      }
    }
    try {
      const response = await getParamsList(
        `/Stockmanagement/closingstock/`,
        params
      );
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return error.message;
    }
  }
);


export const postClosingStock = createAsyncThunk("/postClosingStock",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd(`/Stockmanagement/closingstock/`, { ...data });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      onFailure(error.response.data);
      throw error.response.data;
    }
  }
);

// product list api

export const getProductList = createAsyncThunk("/getProductList",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList("/Stockmanagement/product/", params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);

// get by id
export const getCloseStockById = createAsyncThunk("/getCloseStockById",  async (payload) => {
  console.log("payload", payload)
    const { id, params } = payload;
    try {
      const response = await getParamsList(`/Stockmanagement/closingstock/${payload}`, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);