import { createAsyncThunk } from "@reduxjs/toolkit";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";

import { getList, postAdd, postDownloadFile } from "../../helpers/Helper";

export const getReportList = createAsyncThunk(
    "/getReportList",
    async (payload) => {
        var data = payload.ParamsData;
        var params = {};
        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] !== "" && k !== "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            if (payload.objData.file_format == 5) {
                const response = await postAdd(
                    `/reports/generic_export/`,
                    payload.objData,
                    params
                );
                if (response) {
                    return { response: response.data };
                } else {
                    throw new Error(response);
                }
            } else {
                const response = await postDownloadFile(
                    `/reports/generic_export/`,
                    payload.objData,
                    params
                );
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Your Report Generate Successfully.!
                            </p>
                        </div>
                    ),
                });
            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);