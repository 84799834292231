import { exact } from "prop-types";
import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/login",
    component: lazy(() =>
      import("../../view/pages/authentication/login/username-login")
    ),
    layout: "FullLayout",
  },

  // errors
  {
    path: "/pages/error-page-404",
    component: lazy(() => import("../../view/pages/error/404/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-403",
    component: lazy(() => import("../../view/pages/error/403/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-500",
    component: lazy(() => import("../../view/pages/error/500/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-502",
    component: lazy(() => import("../../view/pages/error/502/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-503",
    component: lazy(() => import("../../view/pages/error/503/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },

  //  Side Menu
  {
    path: "/pages/attendance",
    component: lazy(() => import("../../view/pages/Sidemenu/Attendance")),
    layout: "VerticalLayout",
  },

  // Footter Menu
  {
    path: "/pages/openings-stock",
    component: lazy(() => import("../../view/pages/Sidemenu/OpentStock/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/openings-stock/view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/OpentStock/OpeningStockView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/openings-stock/add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/OpentStock/OpeningStockAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/closing-stock",
    component: lazy(() => import("../../view/pages/Sidemenu/ClosingStock")),
    layout: "VerticalLayout",
  },
  {
    path: "/closing-stock/view/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/ClosingStock/ClosingStockView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/closing-stock/add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/ClosingStock/ClosingStockAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-report",
    component: lazy(() => import("../../view/pages/Sidemenu/StockReport/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/profile",
    component: lazy(() => import("../../view/pages/Sidemenu/Profile/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/stock-management",
    component: lazy(() => import("../../view/pages/Sidemenu/StockManagement/index")),
    layout: "VerticalLayout",
    exact: true,
  },

  {
    path: "/stock-management/view/:id",
    component: lazy(() => import("../../view/pages/Sidemenu/StockManagement/StockManagementView")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/stock-management/add/:id",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/StockManagement/StockManagementAdd")
    ),
    layout: "VerticalLayout",
    exact: true,
  },

];

export default PagesRoutes;
