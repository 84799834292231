import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../helpers/AxiosHelper";
import {
  postAdd,
  getParamsList,
  addParams,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { message } from "antd";

export const getMangeUsersData = createAsyncThunk(
  "/getMangeUsersData",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList(`/users/list/`, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const getMangeUsersDataById = createAsyncThunk(
  "/getMangeUsersDataById",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await getParamsList(`/users/${id}`, {});
      if (response) {
        onSuccess(response);
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      onFailure(error.message);
      throw error.message;
    }
  }
);

export const postMangeUsersData = createAsyncThunk(
  "/postMangeUsersData",
  async (payload) => {
    const { data, onSuccess, OnFailure } = payload;
    try {
      const response = await postAdd(`/users/create/`, { ...data });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response.data?.group_ids) {
        OnFailure(error.response.data.group_ids[0]);
      } else {
        OnFailure(error.response.data.error[0]);
      }
      // OnFailure(error.message);
      OnFailure(error.message);
      throw error.message;
    }
  }
);

export const userNameValidation = createAsyncThunk(
  "/userNameValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/username/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.username[0]);
        // yield put(InputChangeValue({ key: "username", value: "" }));
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userEmailValidation = createAsyncThunk(
  "/userEmailValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/useremail/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error[0]);
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userMobileValidation = createAsyncThunk(
  "/userMobileValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/userphone/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error[0]);
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userActive = createAsyncThunk("/userActive", async (payload) => {
  const { id, onSuccess, OnFailure } = payload;
  try {
    const response = await postAdd(`/users/useractive/${id}`, {});
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    if (error.response) {
      OnFailure(error.response.data.phone);
    } else {
      OnFailure("Sorry! Getting from server side issue!");
    }
    throw error.message;
  }
});
export const userInActive = createAsyncThunk(
  "/userInActive",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd(`/users/userinactive/${id}`, {});
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        onFailure(error.response.data.phone);
        throw error.response.data.phone;
      } else {
        onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);

// export const postMangeUsersDataById = createAsyncThunk(
//   "/postMangeUsersDataById",
//   async (payload) => {
//     try {
//       const response = await postAdd(`/masters/branch/${id}`, { ...payload });
//       if (response.status >= 200 && response.status < 300) {
//         return response.data;
//       } else {
//         throw new Error(response);
//       }
//     } catch (error) {
//       throw error.message;
//     }
//   }
// );
export const editMangeUsersDataById = createAsyncThunk(
  "/editMangeUsersDataById",
  async (payload) => {
    const { url, data, onSuccess, onFailure } = payload;
    try {
      const response = await axiosInstance.put(url, {
        ...data,
      });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        onFailure(error.response.data.message);
        throw error.response.data.phone;
      } else {
        onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);
export const deleteMangeUsersDataById = createAsyncThunk(
  "/deleteMangeUsersDataById",
  async (payload) => {
    const { url } = payload;
    try {
      const response = await postDelete(url);
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
