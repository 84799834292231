import React, { useState } from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { switchable } from "./code.js";

import {
  Card,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Button,
} from "antd";
import { RiCodeSSlashLine } from "react-icons/ri";

const { Option } = Select;

export default function SwitchablePicker(props) {
  const {  onChange, onBlur, PickerType, PickerTypeValue } = props;

  // const [checkedCode, setCheckedCode] = useState(false);
  // const [codeClass, setCodeClass] = useState(false);

  // function toggleChecked() {
  //   setTimeout(() => setCodeClass(!codeClass), 100);
  //   setCheckedCode(!checkedCode);
  // }

  function PickerWithType({ type, onChange }) {
    // if (type === "time") return <TimePicker onChange={onChange} />;
    if (type === "date") return <DatePicker   {...props} onChange={onChange} />;
    return <DatePicker   {...props} picker={type} onChange={onChange} />;
  }

  function handleChange(value){
    // setType(value)
    PickerType(value)
  }

  // const [type, setType] = useState("date");

  return (
    <Space size={24}>
      <Select value={PickerTypeValue} onChange={(e) => handleChange(e)}>
        {/* <Option value="time">Time</Option> */}
        <Option value="date">Date</Option>
        <Option value="week">Week</Option>
        <Option value="custom">Custom</Option>
        {/* <Option value="quarter">Quarter</Option> */}
        {/* <Option value="year">Year</Option> */}
      </Select>
      {PickerTypeValue != "custom" && <PickerWithType type={PickerTypeValue}
   
      onChange={(value) => onChange(value)} />}
    </Space>
  );
}
