import React, { useState, createElement, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col, Tooltip } from "antd";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import logoSmall from "../../../assets/images/logo/logo-small.svg";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";
import { sidebarEnable } from "../../../store/customise/customise";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdOutlineViewInAr } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";

import "../../components/header/index.css";

const { Sider } = Layout;
function Sidebar(props) {
  const { visible, setVisible } = props;


  // Redux
  const customise = useSelector((state) => state.customise);

  // Collapsed
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (customise.sidebarCollapsed) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [customise]);

  // Location
  const location = useLocation();
  const { pathname } = location;

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
};

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
    className: "trigger",
    onClick: toggle,
  });
  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={customise.sidebarMenuEnable}
        width={256}
        className="hp-sidebar hp-bg-color-black-0 hp-bg-color-dark-100 "
      >
        {/* <MenuItem onClose={onClose} /> */}

        <Row gutter={[12, 12]} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Tooltip placement="topRight" title="Stock Management">
              <Link to="/pages/stock-management">
                <Button
                  type="text"
                  icon={
                    <div className="hp-position-relative">
                      <IoOpenOutline
                        className="hp-text-color-dark-60"
                        style={{
                          fontSize: "30px",
                          color: "#b2bec3 ",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  }
                />
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginLeft: "10px",
                    marginBottom: "20px",
                    display: `  ${
                      props.sidebarMenuEnable == true ? "none" : "inline"
                    }`,
                  }}
                >
                  Stock Management
                </span>
              </Link>
            </Tooltip>
          </Col>
          {/* <Col span={24}>
            <Tooltip placement="topRight" title="Closing Stock">
              <Link to="/pages/closing-stock">
                <Button
                  type="text"
                  icon={
                    <div className="hp-position-relative">
                      <IoCloseCircleOutline
                        className="hp-text-color-dark-60"
                        style={{
                          fontSize: "30px",
                          color: "#b2bec3 ",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  }
                />
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginLeft: "10px",
                    marginBottom: "20px",
                    display: `  ${
                      props.sidebarMenuEnable == true ? "none" : "inline"
                    }`,
                  }}
                  visible="false"
                >
                  Closing Stock
                </span>
              </Link>
            </Tooltip>
          </Col> */}
        </Row>

        {/* <MenuFooter onClose={onClose} collapsed={props.sidebarMenuEnable} /> */}
      </Sider>
      <MenuMobile
        onClose={() => props.sidebarEnable(false)}
        visible={props.sidebarMenuEnable}
      />
      {/* <MenuMobile onClose={onClose} visible={visible} /> */}
    </>
  );
}

function mapStateToProps({ customise }) {
  return {
    sidebarMenuEnable: customise.sidebarMenuEnable,
    sidebarCollapseButton: customise.sidebarCollapseButton,
  };
}

export default connect(mapStateToProps, {
  sidebarEnable,
})(Sidebar);
