import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getAttendanceList } from "./attendanceAction";

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    // lateReasonModel: false,
    rowdata: {},
    attendanceList: [],
    attendanceParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    RejectMode: false,
    filterStatus: false,
    rejectReasonObjData: {},
  },
  reducers: {
    setAttendanceParams: (state, action) => {
      return {
        ...state,
        attendanceParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    clearAttendance: (state, action) => {
      return {
        ...state,
        loading: true,
        attendanceList: [],
        filterStatus: false,
        attendanceParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //post Data
      .addCase(getAttendanceList.pending, (state, action) => {
        state.status = "getAttendanceList loading";
        state.loading = true;
      })
      .addCase(getAttendanceList.fulfilled, (state, action) => {
        state.status = "getAttendanceList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.attendanceParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.attendanceList = action.payload.response.results;
        state.attendanceParams = {
          ...state.attendanceParams,
          no_of_pages: noofpages,
        }
      })
      .addCase(getAttendanceList.rejected, (state, action) => {
        state.status = "getAttendanceList failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  setAttendanceParams,
  isDrawerVisible,
  clearAttendance,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
