import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
  postFormData,
  putImg,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

export const getManageCompany = createAsyncThunk(
  "/getManageCompany",
  async (payload) => {
    try {
      const response = await getList("/system/dynamicsettings/", {});
      if (response.data) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
      return error.message;
    }
  }
);
export const manageCompanyAdd = createAsyncThunk(
  "/manageCompanyAdd",
  async (payload) => {
    try {
      const response = await postAdd("/system/dynamicsettings/", payload);
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Manage Company Updated Successfully.!
              </p>
            </div>
          ),
        });
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Updated Manage Company. Please try again!
              </p>
            </div>
          ),
        });
      }
    } catch (error) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
      return error.message;
    }
  }
);
export const getCompanyLogoUpload = createAsyncThunk(
  "/getCompanyLogoUpload",
  async (payload) => {
    try {
      const response = await putImg(
        "/system/dynamicsettings/" + payload.imageType,
        {
          value: payload.file,
        }
      );
      return {
        response,
      };
    } catch (error) {
      return error.message;
    }
  }
);
