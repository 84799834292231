import { createAsyncThunk } from "@reduxjs/toolkit";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

import { getList, getParamsList } from "../../helpers/Helper";

const seralizeParams = (payload) => {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  return params;
};

export const getUserPermissions = createAsyncThunk(
  "/getUserPermissions",
  async (payload) => {
    try {
      const response = await getList("/users/userpermissions/", {});
      console.log(response);
      if (response.data) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
// export const getWarehouseList = createAsyncThunk(
//   "/getWarehouseList",
//   async (payload) => {
//     var params = seralizeParams(payload);
//     try {
//       const response = await getList("/Stockmanagement/warehouse/", params);
//       if (response) {
//         return response;
//       } else {
//         throw new Error(response);
//       }
//     } catch (error) {
//       return error.message;
//     }
//   }
// );


export const getWarehouseList = createAsyncThunk("/getWarehouseList",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList("/masters/mini/warehouse/", params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);

export const getMiniUserList = createAsyncThunk("/getMiniUserList",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList("/users/mini/users/", params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);


export const getCategoryList = createAsyncThunk("/getCategoryList",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList("/Stockmanagement/category/", params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);