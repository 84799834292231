import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../helpers/AxiosHelper";
import { postLogin } from "../../../helpers/Helper";

export const loginUser = createAsyncThunk("/loginUser", async (payload) => {
  const { loginObg, onSuccess, OnFailure } = payload;
  try {
    const response = await postLogin("/users/login/", loginObg);
    console.log(response);
    if (response.status >= 200 && response.status < 300) {
      const tokens = response.data.tokens;
      localStorage.setItem("access_token", tokens.access);
      localStorage.setItem("currentToken", tokens.refresh);
      localStorage.setItem("User_full_name", response.data.name);
      localStorage.setItem("username", response.data.username);
      onSuccess(response.data);
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    console.log(error);
    if (error.data.detail) {
      OnFailure(error.data.detail);
      throw error.data.detail;
    } else {
      OnFailure("In-correct Login Details");
      throw error.message;
    }
  }
});
