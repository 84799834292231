import { createSlice } from "@reduxjs/toolkit";
import {
    getStockDashboard,
    // old
    getclosingStockByDate,
    getOpeningStockByDate, 
    getProductList,
    getWeakDashboard,
    // getTodayDashboard
   } from "./dashboardActions";
import moment from "moment";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    drawer2: false,
    pageParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    dashboardProduct:[],
    pageGraphParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      dateType: "date",
      start_date: moment(),
      end_date: moment(),
    },
    weakDashboardData: [],
    weakDashboardCategory:[],

    weakData: [],


    // old code
    closingStockCount: [],
    openingStockCount: [],
    productList: [],
    productLoading: false,
    productParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    productCount: 0,
  },
  
  reducers: {
    clearGraphData: (state, action) => {
      return {
        ...state,
        pageGraphParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          dateType: "date",
          start_date: moment(),
          end_date: moment(),
        },
      }
    },  
    clearClosingStock: (state, action) => {
      return {
        ...state,
        loading: true,
        // closingStockCount: [],
        // openingStockCount: [],
        dashboardProduct:[],
        pageParams: {
          // date: moment().format("YYYY-MM-DD"),
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        pageGraphParams: {
          ...state.pageGraphParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    isDrawerVisible2: (state, action) => {
      return {
        ...state,
        drawer2: action.payload,
      };
    },
    changeParams: (state, action) => {
      state.pageParams = action.payload;
    },
    changeGraphParams: (state, action) => {
      state.pageGraphParams = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
    .addCase(getStockDashboard.pending, (state, action) => {
      state.status = "getclosingStockByDate loading";
      state.loading = true;
    })
    .addCase(getStockDashboard.fulfilled, (state, action) => {
      state.status = "getclosingStockByDate succeeded";
      state.loading = false;
      var noofpages = Math.ceil(
        action.payload.response.count / state.pageParams.page_size
      );
      state.dashboardProduct = action.payload.response;
      state.pageParams = {
        ...state.pageParams,
        no_of_pages: noofpages,
      };
    })
    .addCase(getStockDashboard.rejected, (state, action) => {
      state.status = "getclosingStockByDate failed";
      state.loading = false;
      state.error = action.error.message;
    })

    .addCase(getWeakDashboard.pending,(state, action) => {
      state.status = "getWeakDashboard loading";
      state.loading = true;
    })
    .addCase(getWeakDashboard.fulfilled, (state, action) => {
      var value = [
        {
          name: "Opening Stock",
          data: [],
        },
        {
          name: "Dispatch",
          data: [],
        },
        {
          name: "Order Placed",
          data: [],
        },
        {
          name: "Production Quantity",
          data: [],
        },
        {
          name: "Pending Dispatch",
          data: [],
        },
        {
          name: "Closing Stock",
          data: [],
        },
      ];
      var categoty = [];
      const data = action.payload;
      Object.keys(data).forEach((key) => {
        categoty.push(key);
        value[0].data.push(data[key].opening_stock_total);
        value[1].data.push(data[key].dispatch_total);
        value[2].data.push(data[key].order_placed_total);
        value[3].data.push(data[key].production_quantity_total);
        value[4].data.push(data[key].pending_dispatch_total);
        value[5].data.push(data[key].closing_stock_total);
      });
      state.status = "getWeakDashboard succeeded";
      state.loading = false;
      state.weakDashboardData = value;
      state.weakDashboardCategory = categoty;

    })
    .addCase(getWeakDashboard.rejected, (state, action) => {
      state.status = "getWeakDashboard failed";
      state.loading = false;
      state.error = action.error.message;
    })
    // .addCase(getWeakDashboard.pending, (state) => {
    //   state.status = "getWeakDashboard loading";
    //   state.loading = true;
    // })
    // .addCase(getWeakDashboard.fulfilled, (state, action) => {
    //   const value = [
    //     { name: "Orders Placed", data: [] },
    //     { name: "Dispatch Orders", data: [] },
    //     { name: "Pending Dispatch", data: [] },
    //   ];
    //   const categoty = ["Sun", "Sat", "Fri", "Thu", "Wed", "Tue", "Mon"];
    //   const data = action.payload.response;
    //   console.log("data", data)
    //   categoty.forEach((day) => {
    //     const dayData = data.find(d => moment(d.date).format('ddd') === day);
    //     if (dayData) {
    //       value[0].data.push(dayData.order_placed_total);
    //       value[1].data.push(dayData.dispatch_total);
    //       value[2].data.push(dayData.pending_dispatch_total);
    //     } else {
    //       value[0].data.push(0);
    //       value[1].data.push(0);
    //       value[2].data.push(0);
    //     }
    //   });
      
    //   state.status = "getWeakDashboard succeeded";
    //   state.loading = false;
    //   state.weakDashboardData = value;
    //   state.weakData = categoty.slice(-6); // Take the last 6 days
    // })
    // .addCase(getWeakDashboard.rejected, (state, action) => {
    //   state.status = "getWeakDashboard failed";
    //   state.loading = false;
    //   state.error = action.error.message;
    // })

    // .addCase(getWeakDashboard.pending, (state, action) => {
    //   state.status = "getWeakDashboard loading";
    //   state.loading = true;
    // })  
    // .addCase(getWeakDashboard.fulfilled, (state, action) => {
      
    //   var data = action.payload.response;
    //   var value = [
    //     {
    //       name: "Order Placed",
    //       data: [
    //         data["today_6"].order_placed_total,
    //         data["today_5"].order_placed_total,
    //         data["today_4"].order_placed_total,
    //         data["today_3"].order_placed_total,
    //         data["today_2"].order_placed_total,
    //         data["today_1"].order_placed_total,
    //         data["today"].order_placed_total,
    //       ],
    //     },
    //     {
    //       name: "Dispatch Orders",
    //       data: [
    //         data["today_6"].dispatch_total,
    //         data["today_5"].dispatch_total,
    //         data["today_4"].dispatch_total,
    //         data["today_3"].dispatch_total,
    //         data["today_2"].dispatch_total,
    //         data["today_1"].dispatch_total,
    //         data["today"].dispatch_total,
    //       ],
    //     },
    //     {
    //       name: "Pending Dispatch",
    //       data: [
    //         data["today_6"].pending_dispatch_total,
    //         data["today_5"].pending_dispatch_total,
    //         data["today_4"].pending_dispatch_total,
    //         data["today_3"].pending_dispatch_total,
    //         data["today_2"].pending_dispatch_total,
    //         data["today_1"].pending_dispatch_total,
    //         data["today"].pending_dispatch_total,
    //       ],
    //     },
    //   ];
    //   var categoty = [
    //     moment().subtract(6, "day").format("ddd"),
    //     moment().subtract(5, "day").format("ddd"),
    //     moment().subtract(4, "day").format("ddd"),
    //     moment().subtract(3, "day").format("ddd"),
    //     moment().subtract(2, "day").format("ddd"),
    //     moment().subtract(1, "day").format("ddd"),
    //     moment().format("ddd"),
    //   ];
    //   // Object.keys(data).forEach((key) => {
    //   //   value[0].data.push(data[key].order_placed_total);
    //   //   value[1].data.push(data[key].dispatch_total);
    //   //   value[2].data.push(data[key].pending_dispatch_total);
    //   // });
    //   state.status = "getWeakDashboard successed";
    //   state.loading = false;
    //   state.weakDashboardData = value;
    //   state.weakData = categoty;
    // })
    // .addCase(getWeakDashboard.rejected, (state, action) => {
    //   state.status = "getWeakDashboard failed";
    //   state.loading = false;
    //   state.error = action.error.message;
    // })


      // // today dashboard

      // .addCase(getTodayDashboard.pending, (state, action) => {
      //   state.status = "getTodayDashboard loading";
      //   state.loading = true;
      // })
      // .addCase(getTodayDashboard.fulfilled, (state, action) => {
      //   var data = action.payload.response;
      //   var value = [
      //     {
      //       name: "Order Placed",
      //       data: [data["today"].order_placed_total],
      //     },
      //     {
      //       name: "Dispatch Orders",
      //       data: [data["today"].dispatch_total],
      //     },
      //     {
      //       name: "Pending Dispatch",
      //       data: [data["today"].pending_dispatch_total],
      //     },
      //   ];
      //   var categoty = [moment().format("ddd")];
      //   state.status = "getTodayDashboard succeeded";
      //   state.loading = false;
      //   // state.todayDashboardData = value;
      //   // state.todayData = category;
      //   state.weakDashboardData = value;
      //   state.weakData = categoty;
      // })
      // .addCase(getTodayDashboard.rejected, (state, action) => {
      //   state.status = "getTodayDashboard failed";
      //   state.loading = false;
      //   state.error = action.error.message;
      // })





      // old code
      .addCase(getclosingStockByDate.pending, (state, action) => {
        state.status = "getclosingStockByDate loading";
        state.loading = true;
      })
      .addCase(getclosingStockByDate.fulfilled, (state, action) => {
        state.status = "getclosingStockByDate succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.pageParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.closingStockCount = action.payload.response.results;
        state.pageParams = {
          ...state.pageParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getclosingStockByDate.rejected, (state, action) => {
        state.status = "getclosingStockByDate failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // dashboard opening stock
      .addCase(getOpeningStockByDate.pending, (state, action) => {
        state.status = "getclosingStockByDate loading";
        state.loading = true;
      })
      .addCase(getOpeningStockByDate.fulfilled, (state, action) => {
        state.status = "getclosingStockByDate succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.pageParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.openingStockCount = action.payload.response.results;
        state.pageParams = {
          ...state.pageParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getOpeningStockByDate.rejected, (state, action) => {
        state.status = "getclosingStockByDate failed";
        state.loading = false;
        state.error = action.error.message;
      })



      // open stock products
      .addCase(getProductList.pending, (state, action) => {
        state.status = "getProductList loading";
        state.productLoading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.status = "getProductList succeeded";
        state.productLoading = false;
        state.productCount = action.payload.response.count;
        state.productList = action.payload.response;
      })
      .addCase(getProductList.rejected, (state, action) => {
        state.status = "getProductList failed";
        state.productLoading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  isDrawerVisible,
  isDrawerVisible2,
  clearClosingStock,
  changeParams,
  stockInputChangeValue,
  FilterInputChangeValue,
  changeGraphParams,
  clearGraphData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
