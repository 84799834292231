import { createSlice } from "@reduxjs/toolkit";
import { getReportList } from "./reportsActions";

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportsItemsList: [],
    reportDataList: [],
    modelName: "",
    reportParamsData: { file_format: 5 },
    columnsList: [],
    reportView: false,
    error: '',
    status: '',
    loading: false,
    pageParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    reportsDataCount:0,
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        reportsItemsList: [],
        reportParamsData: { file_format: 5 },
        reportDataList: [],
        columnsList: [],
        reportView: false,
        pageParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    changeParams: (state, action) => {
      return {
        ...state,
        pageParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        reportParamsData: {
          ...state.reportParamsData,
          [action.payload.key]: action.payload.value,
        },
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getReportList.pending, (state, action) => {
      state.status = "getReportList loading";
      state.loading = true;
    }).addCase(getReportList.fulfilled, (state, action) => {
      // state.status = "getReportList succeeded";
      var columns = [];
      if (action.payload.response?.results.length > 0) {
        columns = Object.keys(action.payload.response?.results[0]);
      }
      return {
        ...state,
        status: "getReportList succeeded",
        reportDataList: action.payload.response?.results,
        reportsDataCount: action.payload.response?.count,
        columnsList: columns,
        loading: false,
        reportView: true,
      };
    }).addCase(getReportList.rejected, (state, action) => {
      state.status = "getReportList failed";
      state.loading = false;
      state.error = action.error.message
    })
  }

  // extraReducers: (builder) => {
  //   builder.addCase(getReportList.pending, (state, action) => {
  //     state.status = "getReportList loading";
  //     state.loading = true;
  //   }).addCase(getReportList.fulfilled, (state, action) => {
  //     state.status = "getReportList succeeded";
  //     state.loading = false;
  //     var columns = [];
  //     if (action.payload.length > 0) {
  //       columns = Object.keys(action.payload[0]);
  //     }
  //     return {
  //       ...state,
  //       reportDataList: action.payload,
  //       columnsList: columns,
  //       loading: false,
  //       reportView: true,
  //     };
  //   }).addCase(getReportList.rejected, (state, action) => {
  //     state.status = "getReportList failed";
  //     state.loading = false;
  //     state.error = action.error.message
  //   })
  // }
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  InputChangeValue,
  changeParams,
} = reportsSlice.actions;

export default reportsSlice.reducer;
