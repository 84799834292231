import React, { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Layout, Row, Col, Button, Tooltip, Typography } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import { getUserPermissions } from "../store/system/systemActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./components/header/index.css";
import { RiFileListLine, RiFileCloseLine, RiFileList3Line, RiAccountBoxLine } from "react-icons/ri";

const { Content } = Layout;

function VerticalLayout(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { Text } = Typography;
  // Redux
  const customise = useSelector((state) => state.customise);

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  return (
    <Layout className="hp-app-layout">
      <MenuHeader setVisible={setVisible} visible={visible} />
      <Layout className="hp-bg-color-dark-90">
        {/* <Sidebar visible={visible} setVisible={setVisible} /> */}
        <Content className="hp-content-main" style={{ marginBottom: "60px" }}>
          <Row justify="center">
            {customise.contentWidth == "full" && (
              <Col span={24}>{children}</Col>
            )}

            {customise.contentWidth == "boxed" && (
              <Col xxl={20} xl={22} span={24}>
                {children}
              </Col>
            )}
          </Row>
        </Content>

        <div className="footerEl">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "80%",
              height: "100%",
            }}
          >
            <Tooltip placement="topLeft" title="Stock"  >
              <Link to="/pages/stock-report">
                <Button
                  type="text"
                  style={{ display: "block" }}
                  icon={
                    <div className="hp-position-relative">
                      <RiFileList3Line
                        className="hp-text-color-dark-60"
                        style={{
                          fontSize: "30px",
                          color: "#ed4e53",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  }
                />
                <Text>Stock</Text>
              </Link>
            </Tooltip>

            <Tooltip placement="topLeft" title="Stock Management">
              <Link to="/pages/stock-management">
                <Button
                  type="text"
                  style={{ display: "block" }}
                  icon={
                    <div className="hp-position-relative">
                      <RiFileListLine
                        className="hp-text-color-dark-60"
                        style={{
                          fontSize: "30px",
                          color: "#ed4e53 ",
                          fontWeight: "700",
                          marginRight: "-75px",
                        }}
                      />
                    </div>
                  }
                />
                <Text> Stock Management</Text>
              </Link>
            </Tooltip>

            {/* <Tooltip placement="topLeft" title="Closing Stock">
              <Link to="/pages/closing-stock">
                <Button
                  type="text"
                  style={{ display: "block" }}
                  icon={
                    <div className="hp-position-relative">
                      <RiFileCloseLine
                        className="hp-text-color-dark-60"
                        style={{
                          fontSize: "30px",
                          color: "#ed4e53 ",
                          fontWeight: "700",
                          marginLeft: "20px",
                        }}
                      />

                    </div>
                  }
                />
                <Text>Closing Stock</Text>
              </Link>
            </Tooltip> */}

            <Tooltip placement="topLeft" title="Profile">
              <Link to="/pages/profile">
                <Button
                  type="text"
                  style={{ display: "block" }}
                  icon={
                    <div className="hp-position-relative">
                      <RiAccountBoxLine
                        className="hp-text-color-dark-60"
                        style={{
                          fontSize: "30px",
                          color: "#ed4e53 ",
                          fontWeight: "700",
                          marginLeft: "0px",
                        }}
                      />

                    </div>
                  }
                />
                <Text>Profile</Text>
              </Link>
            </Tooltip>
          </div>
        </div>

        {/* <MenuFooter /> */}
      </Layout>

      <ScrollTop />
    </Layout>
  );
}
export default VerticalLayout;
