import { createSlice } from "@reduxjs/toolkit";

import { getOpeaningStockList, postOpeningStock, getProductList, getOpenStockById, getYestClosingStock, getOpeaningStockListByWareHouse } from "./openingStockActions";
import moment from "moment";

const opeaningStockSlice = createSlice({
  name: "opeaningstock",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    StockList: [],
    opeaningStockParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RejectMode: false,
    filterStatus: false,
    rejectReasonObjData: {},
    selectedData: {},
    productList: [],
    productParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    productCount: 0,
    filterParamsData: {},
    closingStockList: {},
    openStockList: [],
  },
  reducers: {
    changeWarehouseParams: (state, action) => {
      state.warehouseParams = action.payload;
    },

    setOpeaningStockParams: (state, action) => {
      return {
        ...state,
        opeaningStockParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      state.drawer = action.payload;
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        opeaningStockParams: {
          ...state.opeaningStockParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setFilterParams: (state, action) => {
      return {
        ...state,
        filterParamsData: { ...state.filterParamsData, ...action.payload },
      };
    },
    clearOpeiningStock: (state, action) => {
      return {
        ...state,
        loading: true,
        opeaningStockParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        productList: [],
        selectedData: {},
      };
    },
    changeParams: (state, action) => {
      state.opeaningStockParams = action.payload;
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    stockInputChangeValue: (state, action) => {
      var selectedStockItem = state.productList.map((item) => {
        if (item.id == action.payload.item.id) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        productList: selectedStockItem,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOpeaningStockList.pending, (state, action) => {
        state.status = "getOpeaningStockList loading";
        state.loading = true;
      })
      .addCase(getOpeaningStockList.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        state.status = "getOpeaningStockList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.opeaningStockParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.StockList = action.payload.response;
        state.openStockList = action.payload.response.results;
        state.opeaningStockParams = { ...state.opeaningStockParams, no_of_pages: noofpages, };
      })
      .addCase(getOpeaningStockList.rejected, (state, action) => {
        state.status = "getOpeaningStockList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getOpeaningStockListByWareHouse.pending, (state, action) => {
        state.status = "getOpeaningStockListByWareHouse loading";
        state.loading = true;
      })
      .addCase(getOpeaningStockListByWareHouse.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        state.status = "getOpeaningStockListByWareHouse succeeded";
        state.loading = false;
        // var noofpages = Math.ceil(
        //   action.payload.response.count / state.opeaningStockParams.page_size
        // );
        // state.listCount = action.payload.response.count;
        // state.StockList = action.payload.response.results;
        state.openStockList = action.payload.response;
        // state.opeaningStockParams = { ...state.opeaningStockParams, no_of_pages: noofpages, };
      })
      .addCase(getOpeaningStockListByWareHouse.rejected, (state, action) => {
        state.status = "getOpeaningStockListByWareHouse failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // open stock products
      .addCase(getProductList.pending, (state, action) => {
        state.status = "getProductList loading";
        state.loading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.status = "getProductList succeeded";
        state.loading = false;
        state.productCount = action.payload.response.count;
        state.productList = action.payload.response;
      })
      .addCase(getProductList.rejected, (state, action) => {
        state.status = "getProductList failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // post opening stock
      .addCase(postOpeningStock.pending, (state, action) => {
        state.status = "postOpeningStock loading";
        state.loading = true;
      })
      .addCase(postOpeningStock.fulfilled, (state, action) => {
        state.status = "postOpeningStock succeeded";
        state.loading = false;
      })
      .addCase(postOpeningStock.rejected, (state, action) => {
        state.status = "postOpeningStock failed";
        state.loading = false;
        state.error = action.error.message;
      })


      .addCase(getOpenStockById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getOpenStockById.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.selectedData = response;
      })
      .addCase(getOpenStockById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })


      .addCase(getYestClosingStock.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getYestClosingStock.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.closingStockList = response;
      })
      .addCase(getYestClosingStock.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  setOpeaningStockParams,
  isDrawerVisible,
  clearOpeiningStock,
  changeParams,
  setSelectedData,
  changeWarehouseParams,
  InputChangeValue,
  stockInputChangeValue,
  setFilterParams,
  FilterInputChangeValue
} = opeaningStockSlice.actions;
export default opeaningStockSlice.reducer;
