import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	getList,
	getParamsList,
	postAdd,
	postEdit,
	postFormData,
	postDelete,
} from "../../../helpers/Helper";


// Stock management List
export const getStockManagementList = createAsyncThunk(
	"/getStockManagementList",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
			  if (k === "currentSort" || k === "sortOrder") {
				params.ordering = data.sortOrder + data.currentSort;
			  } else {
				if (data[k] !== "" && k !== null && k !== "no_of_pages") {
				  if (k == "start_date") {
					params[k] = moment(data[k]).format("YYYY-MM-DD");
				  } else if (k == "end_date") {
					params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
				  } else {
					params[k] = data[k];
				  }
				}
			  }
			}
		  }
		try {
			// const response = await getParamsList(
			//   `/Stockmanagement/closingstockbydate/{warehouse}/{date}/`,
			//   params
			// );
			const response = await getParamsList(
				"/stockmanagement/stockmanagement/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// get by id
export const getStockManagementById = createAsyncThunk(
	"/getStockManagementById",
	async (payload) => {
		const { id, params } = payload;
		try {
			const response = await getParamsList(
				`/stockmanagement/stockmanagement/${payload}/`,
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);

// product list api

export const getProductList = createAsyncThunk(
	"/getProductList",
	async (payload) => {
		const { params } = payload;
		try {
			const response = await getParamsList(
				"/masters/mini/product/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);


// get current stock list 
export const getCurrentStock = createAsyncThunk(
	"/getCurrentStock",
	async (payload) => {
		const { params } = payload;
		try {
			const response = await getParamsList(
				"/stockmanagement/current_data",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);

// post stock management

export const postStockManagement = createAsyncThunk(
	"/postStockManagement",
	async (payload) => {
		const { data, onSuccess, onFailure } = payload;
		try {
			const response = await postAdd(`/stockmanagement/stockmanagement/`, {
				...data,
			});
			if (response.status >= 200 && response.status < 300) {
				onSuccess(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			onFailure(error.response.data);
			throw error.response.data;
		}
	}
);








// old code
export const getOpeaningStockListByWareHouse = createAsyncThunk(
	"/getOpeaningStockListByWareHouse",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					// (data[k] !== "" && k !== null && k !== "no_of_pages")
					if (data[k] !== "" && k !== "no_of_pages") {
						if (k === "date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			// const response = await getParamsList(
			//   `/Stockmanagement/closingstockbydate/{warehouse}/{date}/`,
			//   params
			// );
			const response = await getParamsList(
				"/Stockmanagement/openingstockbydate/" +
					payload.warehouse +
					"/" +
					payload.date +
					"/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);




export const postOpeningStock = createAsyncThunk(
	"/postOpeningStock",
	async (payload) => {
		const { data, onSuccess, onFailure } = payload;
		try {
			const response = await postAdd(`/Stockmanagement/openingstock/`, {
				...data,
			});
			if (response.status >= 200 && response.status < 300) {
				onSuccess(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			onFailure(error.response.data);
			throw error.response.data;
		}
	}
);




export const getYestClosingStock = createAsyncThunk(
	"/getYestClosingStock",
	async (payload) => {
		const { params } = payload;
		try {
			const response = await getParamsList(
				`/Stockmanagement/closing/${params.warehouse_id}`,
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);
