import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getMangeUsersData,
  postMangeUsersData,
  editMangeUsersDataById,
  getMangeUsersDataById,
  deleteMangeUsersDataById,
  userNameValidation,
  userEmailValidation,
  userMobileValidation,
} from "./manageUserActions";

const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState: {
    filterStatus: false,
    loading: false,
    status: "",
    error: "",
    drawer: false,
    list: [],
    count: 0,
    selectedData: {},
    pageParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    changeParams: (state, action) => {
      state.pageParams = action.payload;
      // return {
      //   ...state,
      //   pageParams: {
      //     ...state.pageParams,
      //     no_of_pages: Math.floor(
      //       action.payload.count / state.pageParams.page_size
      //     ),
      //   },
      // };
    },
    clearUserData: (state, action) => {
      return {
        ...state,
        loading: false,
        selectedData: {},
        list: [],
        pageParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    setSelectedData: (state, action) => {
      const { data } = action.payload;
      state.selectedData = data;
    },
    SelecteChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //post Data
      .addCase(postMangeUsersData.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(postMangeUsersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        // state.masterMenuItemsList = action.payload;
      })
      .addCase(postMangeUsersData.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //get Data
      .addCase(getMangeUsersData.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getMangeUsersData.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.list = response.results;
        state.count = response.count;
      })
      .addCase(getMangeUsersData.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //get Data By Id
      .addCase(getMangeUsersDataById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getMangeUsersDataById.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.selectedData = {
          ...response,
          groups: response.groups?.find(e => e.id)
        };
      })
      .addCase(getMangeUsersDataById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(editMangeUsersDataById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(editMangeUsersDataById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(editMangeUsersDataById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteMangeUsersDataById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(deleteMangeUsersDataById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(deleteMangeUsersDataById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userNameValidation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(userNameValidation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(userNameValidation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userEmailValidation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(userEmailValidation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(userEmailValidation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userMobileValidation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(userMobileValidation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(userMobileValidation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
  setSelectedData,
  changeParams,
  checkPermission,
  checkAppPermission,
  isDrawerVisible,
  clearUserData,
  SelecteChangeValue,
} = manageUsersSlice.actions;

export default manageUsersSlice.reducer;
