// import { createSlice } from "@reduxjs/toolkit";
import { createSlice, current } from "@reduxjs/toolkit";
import {
  getProfileList,
  getProfilebyID,
  getFilteredProfileList,
  submitProfile,
  getPermissionsList,
  getManualRegProfileList,
} from "./profileActions";
const manageProfileSlice = createSlice({
  name: "manageProfile",
  initialState: {
    listCount: 0,
    profileList: [],
    adminProfileList: [],
    adminListCount: 0,
    manualRegProfileList: [],
    manualRegCount: 0,
    appPermissionsList: [],
    checkedPermissions: [],
    profileData: {},
    model: false,
    loading: false,
    status: "",
    error: "",
    permissionsLoading: false,
    profileParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    InputChangeValue: (state, action) => {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    checkAppPermission: (state, action) => {
      state.appPermissionsList.map((item) => {
        if (item.id === action.payload.appPermission_id) {
          item.appPermissionsChecked = !item.appPermissionsChecked;
          if (item.appPermissionsChecked == true) {
            item.contenttypedetails.map((appitem) => {
              item.appPermissionsChecked = true;
              appitem.permissions.map((permission) => {
                permission.permissionChecked = true;
                state.checkedPermissions = [
                  ...state.checkedPermissions,
                  permission,
                ];
              });
            });
          } else {
            item.contenttypedetails.map((appitem) => {
              item.appPermissionsChecked = false;
              appitem.permissions.map((permission) => {
                permission.permissionChecked = false;
                state.checkedPermissions = state.checkedPermissions.filter(
                  (permission1) => {
                    if (permission1.id !== permission.id) {
                      return true;
                    }
                    return false;
                  }
                );
              });
            });
          }
        }
        return item;
      });
    },

    checkPermission: (state, action) => {
      if (state.appPermissionsList.length !== 0) {
        state.appPermissionsList.forEach((item) => {
          if (action.payload.appPermission_id == item.id) {
            item.contenttypedetails.forEach((appitem) => {
              if (action.payload.contentType_id == appitem.id) {
                appitem.permissions.forEach((permission) => {
                  if (action.payload.permission_id == permission.id) {
                    permission.permissionChecked = action.payload.value;
                    if (action.payload.value === true) {
                      item.appPermissionsChecked = true;
                      appitem.contentTypeChecked = true;
                      state.checkedPermissions = [
                        ...state.checkedPermissions,
                        permission,
                      ];
                    } else {
                      state.checkedPermissions =
                        state.checkedPermissions.filter((permission) => {
                          if (permission.id !== action.payload.permission_id) {
                            return true;
                          }
                          return false;
                        });
                    }
                  }
                });
              }
            });
          }
        });
      }
    },

    setProfileParams: (state, action) => {
      return {
        ...state,
        profileParams: action.payload,
      };
    },
    profileDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    clearProfileData: (state, action) => {
      return {
        ...state,
        loading: false,
        profileData: {},
        // profileList: [],
        appPermissionsList: [],
        checkedPermissions: [],
      };
    },

    clearprofileParams: (state, action) => {
      return {
        ...state,
        loading: true,
        profileParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileList.pending, (state, action) => {
        state.status = "getProfileList loading";
        state.loading = true;
      })
      .addCase(getProfileList.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "getProfileList succeeded";
        state.loading = false;
        state.profileList = response.results;
        state.listCount = response.count;
      })
      .addCase(getProfileList.rejected, (state, action) => {
        state.status = "getProfileList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      // getbyid
      .addCase(getProfilebyID.pending, (state, action) => {
        state.status = "getProfilebyID loading";
        state.loading = true;
      })
      .addCase(getProfilebyID.fulfilled, (state, action) => {
        const { response } = action.payload;
        console.log(response);
        var permissionsIds = response.permissions;
        console.log(response);
        state.appPermissionsList?.map((item) => {
          item.contenttypedetails.map((appitem) => {
            appitem.permissions.map((permission) => {
              if (permissionsIds.indexOf(permission.id) !== -1) {
                permission.permissionChecked = true;
                state.checkedPermissions = [
                  ...state.checkedPermissions,
                  permission,
                ];

                item.appPermissionsChecked = true;
                appitem.contentTypeChecked = true;
              }
            });
          });
        });

        state.status = "getProfilebyID succeeded";
        state.loading = false;
        state.profileData = {
          ...response,
          reporting_to_id: response.groupdetails.reporting_to,
        };
      })
      .addCase(getProfilebyID.rejected, (state, action) => {
        state.status = "getProfilebyID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      // getPermissionsList
      .addCase(getPermissionsList.pending, (state, action) => {
        state.status = "getPermissionsList loading";
        state.loading = true;
      })
      .addCase(getPermissionsList.fulfilled, (state, action) => {
        const { response, id } = action.payload;
        state.status = "getPermissionsList succeeded";
        state.permissionsLoading = false;
        if (state.appPermissionsList.length == 0) {
          var permissionlist = response.map((item) => {
            var contentTypeList = item.contenttypedetails.map((appitem) => {
              var permissionList = appitem.permissions.map((permission) => {
                // permission.permissionChecked = false;
                return {
                  ...permission,
                  permissionChecked: false,
                };
              });
              return {
                ...appitem,
                permissions: permissionList,
                contentTypeChecked: false,
              };
            });
            return {
              ...item,
              appPermissionsChecked: false,
              contenttypedetails: contentTypeList,
            };
          });

          state.appPermissionsList = permissionlist;
        }
      })
      .addCase(getPermissionsList.rejected, (state, action) => {
        state.status = "getPermissionsList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      // submitProfile
      .addCase(submitProfile.pending, (state, action) => {
        state.status = "submitProfile loading";
        state.loading = true;
      })
      .addCase(submitProfile.fulfilled, (state, action) => {
        state.status = "submitProfile succeeded";
        state.loading = false;
      })
      .addCase(submitProfile.rejected, (state, action) => {
        state.status = "submitProfile failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFilteredProfileList.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getFilteredProfileList.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.adminProfileList = response?.results;
        state.adminListCount = response?.count;
      })
      .addCase(getFilteredProfileList.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getManualRegProfileList.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getManualRegProfileList.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.manualRegProfileList = response?.results;
        state.manualRegCount = response?.count;
      })
      .addCase(getManualRegProfileList.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
  checkAppPermission,
  checkPermission,
  clearprofileParams,
  setProfileParams,
  profileDelete,
  clearProfileData,
  apiError,
} = manageProfileSlice.actions;

export default manageProfileSlice.reducer;
