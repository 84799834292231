import { createAsyncThunk } from "@reduxjs/toolkit";
import { getList, postFormData } from "../../helpers/Helper";

export const allMenus = createAsyncThunk("/allMenus", async () => {
  try {
    const response = await getList(`/system/usermenu/`, {});
    return response;
  } catch (err) {
    throw err;
  }
});
export const getMenuItems = createAsyncThunk(
  "/getMenuItems",
  async (payload) => {
    const { code, from } = payload;
    try {
      const response = await getList(`/system/user_menu/${code}`, {});
      return { from, response: response.data };
    } catch (err) {
      throw err;
    }
  }
);
export const postAttachments = createAsyncThunk(
  "/postAttachments",
  async (payload) => {
    const { file, from, onSuccess, onFailure } = payload;
    try {
      const response = await postFormData(`/system/attachment/`, {
        file: file,
      });
      onSuccess(response.data);
      return { from, response: response.data };
    } catch (err) {
      onFailure(err.message);
      throw err;
    }
  }
);
export const postZoneAttachments = createAsyncThunk(
  "/postZoneAttachments",
  async (payload) => {
    const { file, from, onSuccess, onFailure } = payload;
    try {
      const response = await postFormData(`/system/attachment/`, {
        file: file,
      });
      onSuccess(response.data);
      return { from, response: response.data };
    } catch (err) {
      onFailure(err.message);
      throw err;
    }
  }
);
export const postNotificationAttachment = createAsyncThunk(
  "/postNotificationAttachment",
  async (payload) => {
    const { file, from, onSuccess, onFailure } = payload;
    try {
      const response = await postFormData(`/system/attachment/`, {
        file: file,
      });
      onSuccess(response.data);
      return { from, response: response.data };
    } catch (err) {
      onFailure(err.message);
      throw err;
    }
  }
);
export const postPosterAttachment = createAsyncThunk(
  "/postPosterAttachment",
  async (payload) => {
    const { file, onSuccess, onFailure } = payload;
    try {
      const response = await postFormData(`/system/attachment/`, {
        file: file,
      });
      onSuccess(response.data);
      return { response: response.data };
    } catch (err) {
      onFailure(err.message);
      throw err;
    }
  }
);
export const postHostImage = createAsyncThunk(
  "/postHostImage",
  async (payload) => {
    const { file, onSuccess, onFailure } = payload;
    try {
      const response = await postFormData(`/system/attachment/`, {
        file: file,
      });
      onSuccess(response.data);
      return { response: response.data };
    } catch (err) {
      onFailure(err.message);
      throw err;
    }
  }
);
export const postImage = createAsyncThunk("/postImage", async (payload) => {
  const { file, onSuccess, onFailure } = payload;
  try {
    const response = await postFormData(`/system/attachment/`, {
      file: file,
    });
    onSuccess(response.data);
    return { response: response.data };
  } catch (err) {
    onFailure(err.message);
    throw err;
  }
});
export const getUserPermissions = createAsyncThunk(
  "/getUserPermissions",
  async () => {
    try {
      const response = await getList(`/users/userpermissions/`, {});
      if (response) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);

export const getExportMenuItems = createAsyncThunk(
  "/getExportMenuItems",
  async () => {
    try {
      const response = await getList(`/system/user_menu/MENU0009`, {});
      if (response) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);

export const getImportMenuItems = createAsyncThunk(
  "/getImportMenuItems",
  async () => {
    try {
      const response = await getList(`/system/user_menu/MENU0007`, {});
      if (response) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
