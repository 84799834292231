import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { appSettingsAdd, getAppSettings } from "./appsettingActions";

const appsettingsSlice = createSlice({
  name: "appSettings",
  initialState: {
    listCount: 0,
    appSettingsData: {},
    model: false,
  },
  reducers: {
    InputChangeValue: (state, action) => {
      return {
        ...state,
        appSettingsData: {
          ...state.appSettingsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppSettings.pending, (state, action) => {
        state.loading = true;
        state.status = "getAppSettings pending";
      })
      .addCase(getAppSettings.fulfilled, (state, action) => {
        state.status = "getAppSettings succeeded";
        state.loading = false;
        state.appSettingsData = {
          SMS__MSG_VAR: action.payload.SMS__MSG_VAR,
          SMS__NUMBER_VAR: action.payload.SMS__NUMBER_VAR,
          SMS__URL: action.payload.SMS__URL,
          SMTP__BACKEND: action.payload.SMTP__BACKEND,
          SMTP__HOST: action.payload.SMTP__HOST,
          SMTP__PASSWORD: action.payload.SMTP__PASSWORD,
          SMTP__PORT: action.payload.SMTP__PORT,
          SMTP__USER: action.payload.SMTP__USER,
          SMTP__USE_TLS: action.payload.SMTP__USE_TLS,
          THIRDPARTY__URL: action.payload.THIRDPARTY__URL,
          THIRDPARTY__TOKEN: action.payload.THIRDPARTY__TOKEN,
        }
      })
      .addCase(getAppSettings.rejected, (state, action) => {
        state.status = "getAppSettings failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(appSettingsAdd.pending, (state, action) => {
        state.loading = true;
        state.status = "appSettingsAdd pending";
      })
      .addCase(appSettingsAdd.fulfilled, (state, action) => {
        state.status = "appSettingsAdd succeeded";
        state.loading = false;
        state.appSettingsData = {
          ...action.payload,
          SMS__MSG_VAR: action.payload.SMS__MSG_VAR,
          SMS__NUMBER_VAR: action.payload.SMS__NUMBER_VAR,
          SMS__URL: action.payload.SMS__URL,
          SMTP__BACKEND: action.payload.SMTP__BACKEND,
          SMTP__HOST: action.payload.SMTP__HOST,
          SMTP__PASSWORD: action.payload.SMTP__PASSWORD,
          SMTP__PORT: action.payload.SMTP__PORT,
          SMTP__USER: action.payload.SMTP__USER,
          SMTP__USE_TLS: action.payload.SMTP__USE_TLS,
          THIRDPARTY__URL: action.payload.THIRDPARTY__URL,
          THIRDPARTY__TOKEN: action.payload.THIRDPARTY__TOKEN,
        };
      })
      .addCase(appSettingsAdd.rejected, (state, action) => {
        state.status = "appSettingsAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
} = appsettingsSlice.actions;

export default appsettingsSlice.reducer;
