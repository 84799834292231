import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	getList,
	getParamsList,
	postAdd,
	postEdit,
	postFormData,
	postDelete,
} from "../../../helpers/Helper";

export const getOpeaningStockListByWareHouse = createAsyncThunk(
	"/getOpeaningStockListByWareHouse",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					// (data[k] !== "" && k !== null && k !== "no_of_pages")
					if (data[k] !== "" && k !== "no_of_pages") {
						if (k === "date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			// const response = await getParamsList(
			//   `/Stockmanagement/closingstockbydate/{warehouse}/{date}/`,
			//   params
			// );
			const response = await getParamsList(
				"/Stockmanagement/openingstockbydate/" +
					payload.warehouse +
					"/" +
					payload.date +
					"/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getOpeaningStockList = createAsyncThunk(
	"/getOpeaningStockList",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					// (data[k] !== "" && k !== null && k !== "no_of_pages")
					if (data[k] !== "" && k !== "no_of_pages") {
						if (k === "date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			// const response = await getParamsList(
			//   `/Stockmanagement/closingstockbydate/{warehouse}/{date}/`,
			//   params
			// );
			const response = await getParamsList(
				"/Stockmanagement/openingstock/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postOpeningStock = createAsyncThunk(
	"/postOpeningStock",
	async (payload) => {
		const { data, onSuccess, onFailure } = payload;
		try {
			const response = await postAdd(`/Stockmanagement/openingstock/`, {
				...data,
			});
			if (response.status >= 200 && response.status < 300) {
				onSuccess(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			onFailure(error.response.data);
			throw error.response.data;
		}
	}
);

// product list api

export const getProductList = createAsyncThunk(
	"/getProductList",
	async (payload) => {
		const { params } = payload;
		try {
			const response = await getParamsList(
				"/Stockmanagement/product/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);

// get by id
export const getOpenStockById = createAsyncThunk(
	"/getOpenStockById",
	async (payload) => {
		const { id, params } = payload;
		try {
			const response = await getParamsList(
				`/Stockmanagement/openingstock/${payload}`,
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);

export const getYestClosingStock = createAsyncThunk(
	"/getYestClosingStock",
	async (payload) => {
		const { params } = payload;
		try {
			const response = await getParamsList(
				`/Stockmanagement/closing/${params.warehouse_id}`,
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);
