import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Button,
  Drawer,
  Space,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import {
  isDrawerVisible,
  changeGraphParams,
  clearClosingStock,
  FilterInputChangeValue 
} from "../../store/sideMenu/dashboard/dashboardSlice";
import {
  getclosingStockByDate,
  getOpeningStockByDate,
  getWeakDashboard,
  getTodayDashboard
} from "../../store/sideMenu/dashboard/dashboardActions";
import { getWarehouseList } from "../../store/mini/miniActions";
import SwitchablePicker from "../components/data-entry/datepicker/switchable";

var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();

function Filter() {
  const drawer = useSelector((state) => state.dashboard.drawer);
  const loading = useSelector((state) => state.dashboard.loading);
  const pageGraphParams = useSelector((state) => state.dashboard.pageGraphParams);
  const warehouseParams = useSelector((state) => state.mini.warehouseParams);
  const warehoueList = useSelector((state) => state.mini.warehoueList);
  const weakDashboardData = useSelector((state) => state.dashboard.weakDashboardData);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;

//  function onFinish() {
//     dispatch(changeGraphParams({ ...pageGraphParams }));
//     dispatch(
//       getWeakDashboard({
//         ...pageGraphParams,
//         warehouse: pageGraphParams?.warehouse,
//         start_date: moment(pageGraphParams.start_date).startOf(pageGraphParams.dateType),
//         end_date: moment(pageGraphParams.start_date).endOf(pageGraphParams.dateType),
//         page: 1,
//         search: "",
//         page_size: 10,
//       })
//     );
//     dispatch(isDrawerVisible(false));
//   }

function onFinish() {
  const { dateType, start_date, end_date, warehouse } = pageGraphParams;
  let startDate, endDate;
  if (dateType === "custom") {
    startDate = start_date;
    endDate = end_date;
  } else {
    startDate = moment(start_date).startOf(dateType);
    endDate = moment(start_date).endOf(dateType);
  }

  dispatch(changeGraphParams({ ...pageGraphParams }));
  dispatch(
    getWeakDashboard({
      ...pageGraphParams,
      warehouse,
      start_date: startDate,
      end_date: endDate,
      page: 1,
      search: "",
      page_size: 10,
    })
  );
  dispatch(isDrawerVisible(false));
}

function clearFilter() {
  dispatch(
    changeGraphParams({
      ...pageGraphParams,
      dateType: "date",
      start_date: moment(),
      end_date: moment(),
      page: 1,
      search: "",
      page_size: 10,
      warehouse:"",
    })
  );
  dispatch(
    getWeakDashboard({
      ...pageGraphParams,
      dateType: "date",
      start_date: moment(),
      end_date: moment(),
      warehouse:"",
      page: 1,
      search: "",
      page_size: 10,
    })
  );
  dispatch(isDrawerVisible(false));
  form.resetFields();
}

console.log("pageGraphParams", pageGraphParams)

  const handleChangeFn = (data) => {
    dispatch(
      changeGraphParams({
        ...pageGraphParams,
        [data.key]: data.value,
      })
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      warehouse: pageGraphParams?.warehouse,
      start_date: pageGraphParams.start_date,
      end_date: pageGraphParams.end_date
    });
  }, [pageGraphParams]);

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck =
          current &&
          current < moment(startDate + " 00:00:01", "YYYY-MM-DD HH:mm:ss");
      }
      if (endDate) {
        endCheck =
          current &&
          current >
          moment(
            moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
            "YYYY-MM-DD HH:mm:ss"
          );
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  }


  

  // function onFinish() {
  //   dispatch(changeGraphParams({ ...pageGraphParams }));
  //   if (pageGraphParams.dateType == "Today") {
  //     dispatch(getTodayDashboard({
  //       ...pageGraphParams,
  //       warehouse: pageGraphParams?.warehouse,
  //       page: 1,
  //       search: "",
  //       page_size: 10,
  //     }));
  //   } else {
  //     dispatch(
  //       getWeakDashboard({
  //         ...pageGraphParams,
  //         warehouse: pageGraphParams?.warehouse,
  //         dateType : pageGraphParams?.dateType,
  //         page: 1,
  //         search: "",
  //         page_size: 10,

  //       })
  //     );
  //   }
  //   dispatch(isDrawerVisible(false));
  // }

 

  // function onFinish() {
  //   dispatch(changeGraphParams({ ...pageGraphParams }));
  //   if (pageGraphParams.dateType === "Today") {
  //     dispatch(getTodayDashboard({
  //       ...pageGraphParams,
  //       warehouse: pageGraphParams?.warehouse,
  //       page: 1,
  //       search: "",
  //       page_size: 10,
  //     }));
  //   } else {
  //     dispatch(
  //       getWeakDashboard({
  //         ...pageGraphParams,
  //         warehouse: pageGraphParams?.warehouse,
  //         dateType: pageGraphParams?.dateType,
  //         page: 1,
  //         search: "",
  //         page_size: 10,
  //       })
  //     );
  //   }
  //   dispatch(isDrawerVisible(false));
  // }

 

  // function clearFilter() {
  //   dispatch(getTodayDashboard({
  //     ...pageGraphParams,
  //     dateType: "Today",
  //     page: 1,
  //     search: "",
  //     page_size: 10,
  //   }));
  //   dispatch(isDrawerVisible(false));
  //   form.resetFields();
  // }




  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Drawer
          title="Filters"
          loading={loading}
          width={420}
          className="hp-drawer-mobile"
          onClose={() => {
            dispatch(isDrawerVisible(false));
          }}
          placement="right"
          open={drawer}
          bodyStyle={{
            paddingBottom: 10,
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Row gutter={[12, 12]}>
              {/* <Col span={24}>
                <Form.Item label="Date" name="date" className="hp-mb-10">
                  <DatePicker
                    style={{ width: "100%" }}
                    picker="date"
                    placeholder="Choose Date"
                    format="DD-MM-YYYY"
                    disabledDate={disableDateRanges({
                      endDate: new Date(),
                    })}
                    onChange={(date) => {
                      dispatch(
                        FilterInputChangeValue({
                          key: "date",
                          value: date == null ? "" : date,
                        })
                      );
                      if (date == null) {
                        dispatch(
                          FilterInputChangeValue({
                            key: "date",
                            value: "",
                          })
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Col> */}

              {/* <Form.Item label="Date" name="dateType" className="hp-mb-0">
                  <Select
                    showSearch
                    placeholder="Select Date"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    optionFilterProp="dateType"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      handleChangeFn({
                        key: "dateType",
                        value: value,
                      });
                    }}
                    value={form.getFieldValue("dateType")}
                  >
                    {dateType.map((date, index) => {
                      return (
                        <Option key={index} value={date.id}>
                          {date.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item> */}

              {/* <Col span={24}>
                <Form.Item
                  label="Select Date"
                  name="start_date"
                  className="hp-mb-0"
                >
                  <SwitchablePicker
                    id="start_date"
                    name="start_date"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    PickerType={(e) => {
                      handleChangeFn({
                        key: "dateType",
                        value: e,
                      });
                    }}
                    PickerTypeValue={pageGraphParams.dateType}
                    disabledDate={disableDateRanges({
                      endDate: new Date(),
                    })}
                    value={pageGraphParams.start_date}
                    onChange={(date) => {
                      handleChangeFn({
                        key: "start_date",
                        value: date == null ? "" : date,
                      });
                      if (date == null) {
                        handleChangeFn({
                          key: "end_date",
                          value: "",
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col> */}

              <Col span={24}>
                {/* <Form.Item
                  label="Select Date"
                  name="start_date"
                  className="hp-mb-0"
                >
                  <SwitchablePicker
                    id="start_date"
                    name="start_date"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    // PickerType={(e) => {
                    //   handleChangeFn({
                    //     key: "dateType",
                    //     value: e,
                    //   });
                    //   if (e !== "custom") {
                    //     handleChangeFn({
                    //       key: "end_date",
                    //       value: moment().endOf(e), // Update the end date based on the selected type
                    //     });
                    //   }
                    // }}
                    // PickerTypeValue={pageGraphParams.dateType}
                    // disabledDate={disableDateRanges({
                    //   endDate: new Date(),
                    // })}
                    // value={pageGraphParams.start_date}
                    // onChange={(date) => {
                    //   handleChangeFn({
                    //     key: "start_date",
                    //     value: date == null ? "" : date,
                    //   });
                    //   if (date == null) {
                    //     handleChangeFn({
                    //       key: "end_date",
                    //       value: "",
                    //     });
                    //   } else if (pageGraphParams.dateType !== "custom") {
                    //     handleChangeFn({
                    //       key: "end_date",
                    //       value: moment(date).endOf(pageGraphParams.dateType),
                    //     });
                    //   }
                    // }}

                    PickerType={(e) => {
                      handleChangeFn({ key: "dateType", value: e });
                      if (e !== "custom") {
                        handleChangeFn({ key: "end_date", value: moment().endOf(e) });
                      }
                    }}
                    PickerTypeValue={pageGraphParams.dateType || "date"}
                    disabledDate={disableDateRanges({ endDate: new Date() })}
                    value={pageGraphParams.start_date}
                    onChange={(date) => {
                      handleChangeFn({ key: "start_date", value: date == null ? "" : date });
                      if (date == null) {
                        handleChangeFn({ key: "end_date", value: "" });
                      } else if (pageGraphParams.dateType !== "custom") {
                        handleChangeFn({ key: "end_date", value: moment(date).endOf(pageGraphParams.dateType) });
                      }
                    }}
                  />
                </Form.Item> */}

                <Form.Item
                  label="Select Date"
                  name="start_date"
                  className="hp-mb-0">
                  <SwitchablePicker
                    id="start_date"
                    name="start_date"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    PickerType={(e) => {
                      console.log(e)
                      dispatch(FilterInputChangeValue({
                        key: "dateType",
                        value: e,
                      }))
                      
                    }}
                    PickerTypeValue={pageGraphParams.dateType}
                    disabledDate={disableDateRanges({
                      endDate: new Date(),
                    })}
                    value={pageGraphParams.start_date}
                    onChange={(date) => {
                      dispatch(FilterInputChangeValue({
                        key: "start_date",
                        value: date == null ? "" : date,
                      }))
                      if (date == null) {
                        dispatch(FilterInputChangeValue({
                          key: "end_date",
                          value: "",
                        }))
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              {pageGraphParams.dateType == "custom" && (
                <>
                  <Col span={24}>
                    <Form.Item
                      label="From Date"
                      name="start_date"
                      className="hp-mb-0"
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        picker="date"
                        placeholder="Choose Date"
                        getPopupContainer={(trigger) => trigger.parentElement}
                        format="DD-MM-YYYY"
                        disabledDate={disableDateRanges({
                          endDate: new Date(),
                        })}
                        value={pageGraphParams.start_date}
                        onChange={(date) => {
                          handleChangeFn({ key: "start_date", value: date == null ? "" : date });
                          if (date == null) {
                            handleChangeFn({ key: "end_date", value: "" });
                          }
                        }}
                        // onChange={(date) => {
                        //   handleChangeFn({
                        //     key: "start_date",
                        //     value: date == null ? "" : date,
                        //   });
                        //   if (date == null) {
                        //     handleChangeFn({
                        //       key: "end_date",
                        //       value: "",
                        //     });
                        //   }
                        // }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="To Date"
                      name="end_date"
                      className="hp-mb-0"
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        picker="date"
                        placeholder="Choose Date"
                        format="DD-MM-YYYY"
                        getPopupContainer={(trigger) => trigger.parentElement}
                        disabledDate={disableDateRanges({
                          endDate: moment().format("YYYY-MM-DD"),
                          startDate: moment(pageGraphParams?.start_date).format(
                            "YYYY-MM-DD"
                          ),
                        })}
                        disabled={!pageGraphParams.start_date}
                        // disabled={
                        //   pageGraphParams.start_date == null ||
                        //   pageGraphParams.start_date == ""
                        //     ? true
                        //     : false
                        // }
                        value={pageGraphParams.end_date}
                        onChange={(date) => {
                          handleChangeFn({ key: "end_date", value: date == null ? "" : date });
                        }}
                        // onChange={(date) => {
                        //   handleChangeFn({
                        //     key: "end_date",
                        //     value: date == null ? "" : date,
                        //   });
                        // }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {/* {pageGraphParams.dateType == "custom" && (
                <>
                  <Col span={24}>
                    <Form.Item
                      label="From Date"
                      name="start_date"
                      className="hp-mb-0"
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        picker="date"
                        placeholder="Choose Date"
                        getPopupContainer={(trigger) => trigger.parentElement}
                        format="DD-MM-YYYY"
                        disabledDate={disableDateRanges({
                          endDate: new Date(),
                        })}
                        onChange={(date) => {
                          handleChangeFn({
                            key: "start_date",
                            value: date == null ? "" : date,
                          });
                          if (date == null) {
                            handleChangeFn({
                              key: "end_date",
                              value: "",
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="To Date"
                      name="end_date"
                      className="hp-mb-0"
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        picker="date"
                        placeholder="Choose Date"
                        format="DD-MM-YYYY"
                        getPopupContainer={(trigger) => trigger.parentElement}
                        disabledDate={disableDateRanges({
                          endDate: moment().format("YYYY-MM-DD"),
                          startDate: moment(pageGraphParams?.start_date).format(
                            "YYYY-MM-DD"
                          ),
                        })}
                        disabled={
                          pageGraphParams.start_date == null ||
                          pageGraphParams.start_date == ""
                            ? true
                            : false
                        }
                        onChange={(date) => {
                          handleChangeFn({
                            key: "end_date",
                            value: date == null ? "" : date,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )} */}
              <Col span={24}>
                <Form.Item
                  label="Warehouse"
                  name="warehouse"
                  className="hp-mb-0"
                >
                  <Select
                    showSearch
                    placeholder="Select Warehouse"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    optionFilterProp="warehouse"
                    maxTagCount="responsive"
                    onSearch={(value) => {
                      dispatch(
                        getWarehouseList({
                          page: 1,
                          search: value,
                          page_size: 10,
                        })
                      );
                    }}
                    onFocus={() => {
                      dispatch(
                        getWarehouseList({
                          page: 1,
                          search: "",
                          page_size: 10,
                        })
                      );
                    }}
                    defaultActiveFirstOption={true}
                    showArrow={true}
                    filterOption={false}
                    onChange={(value) => {
                      handleChangeFn({
                        key: "warehouse",
                        value: value,
                      });
                    }}
                    // defaultValue={warehoueList[0]?.name}
                    value={pageGraphParams.warehouse}
                    onPopupScroll={() => {
                      if (warehouseParams.page < warehouseParams.no_of_pages) {
                        dispatch(
                          getWarehouseList({
                            page: warehouseParams?.page + 1,
                            search: "",
                            page_size: 10,
                          })
                        );
                      }
                    }}
                  >
                    {warehoueList?.map((warehouse, index) => {
                      return (
                        <Option key={index} value={warehouse.id}>
                          {warehouse?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col span={24}>
                <Form.Item
                  label="Category"
                  name="product__category"
                  className="hp-mb-0"
                >
                  <Select
                    showSearch
                    placeholder="Select Category"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    optionFilterProp="product__category"
                    maxTagCount="responsive"
                    onSearch={(value) => {
                      dispatch(
                        getCategoryList({
                          page: 1,
                          search: value,
                          page_size: 10,
                        })
                      );
                    }}
                    onFocus={() => {
                      dispatch(
                        getCategoryList({
                          page: 1,
                          search: "",
                          page_size: 10,
                        })
                      );
                    }}
                    defaultActiveFirstOption={true}
                    showArrow={true}
                    filterOption={false}
                    onChange={(value) => {
                      handleChangeFn({
                        key: "product__category",
                        value: value,
                      });
                    }}
                    value={pageGraphParams.category}
                    onPopupScroll={() => {
                      if (categoryParams.page < categoryParams.no_of_pages) {
                        dispatch(
                          getCategoryList({
                            page: categoryParams?.page + 1,
                            search: "",
                            page_size: 10,
                          })
                        );
                      }
                    }}
                  >
                    {categoryList?.map((category, index) => {
                      return (
                        <Option key={index} value={category.id}>
                          {category?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={[12, 0]}>
              <Col span={24} xs={12} sm={12} md={12}>
                <Button
                  block
                  type="secondary"
                  className="hp-submit-btn hp-mt-10"
                  onClick={() => {
                    clearFilter();
                  }}
                  htmlType="button"
                >
                  Clear
                </Button>
              </Col>
              <Col span={24} xs={12} sm={12} md={12}>
                <Button
                  block
                  type="primary"
                  className="hp-submit-btn hp-mt-10"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Col>
    </Row>
  );
}

export default Filter;
