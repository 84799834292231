import React from "react";
import { Drawer } from "antd";
import { RiCloseFill } from "react-icons/ri";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdOutlineViewInAr } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";
import { Button, Row, Col, Tooltip } from "antd";
import { Link } from "react-router-dom";
import MenuLogo from "../logo";
import MenuItem from "../item";
import MenuFooter from "../footer";
import { RiFileListLine, RiFileCloseLine, RiFileList3Line  } from "react-icons/ri";


export default function MenuMobile(props) {
  const { onClose, visible } = props;

  return (
    <Drawer
      title={<MenuLogo onClose={onClose} />}
      className="hp-mobile-sidebar"
      placement="left"
      closable={true}
      onClose={onClose}
      open={visible}
      width={256}
      closeIcon={
        <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />
      }
    >
      <Row gutter={[12, 12]} style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Link to="/pages/stock-report">
            <Button
              type="text"
              icon={
                <div className="hp-position-relative">
                  <RiFileList3Line
                    className="hp-text-color-dark-60"
                    style={{
                      fontSize: "30px",
                      color: "#ed4e53",
                      fontWeight: "bold",
                    }}
                  />
                </div>
              }
            />
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                marginLeft: "10px",
                color: "#000",
              }}
            >
             Stock Report
            </span>
          </Link>
          
        </Col>
        <Col span={24}>
          <Link to="/pages/stock-management">
            <Button
              type="text"
              icon={
                <div className="hp-position-relative">
                  <RiFileListLine
                    className="hp-text-color-dark-60"
                    style={{
                      fontSize: "30px",
                      color: "#ed4e53",
                      fontWeight: "bold",
                    }}
                  />
                </div>
              }
            />
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                marginLeft: "10px",
                color: "#000",
              }}
            >
              Stock Management
            </span>
          </Link>
        </Col>
        {/* <Col span={24}>
          <Link to="/pages/closing-stock">
            <Button
              type="text"
              icon={
                <div className="hp-position-relative">
                  <RiFileCloseLine
                    className="hp-text-color-dark-60"
                    style={{
                      fontSize: "30px",
                      color: "#ed4e53",
                      fontWeight: "bold",
                    }}
                  />
                </div>
              }
            />
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                marginLeft: "10px",
                color: "#000",
              }}
            >
              Closing Stock
            </span>
          </Link>
        </Col> */}
      </Row>

      {/* <MenuItem onClose={onClose} /> */}

      {/* <MenuFooter onClose={onClose} collapsed={false} /> */}
    </Drawer>
  );
}
