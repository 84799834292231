import React, { useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Layout, Button, Row, Col, Image, Badge, Switch } from "antd";
import { Link } from "react-router-dom";
import { Home, TickSquare, CloseSquare, Danger } from "react-iconly";
import { connect, dispatch } from "react-redux";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";
import { Search } from "react-iconly";
import { HomeOutlined } from "@ant-design/icons";
import HeaderSideMenu from "./HeaderSideMenu";
import HeaderMenu from "./HeaderMenu";
import HeaderUser from "./HeaderUser";
import WhiteLogo from "../../../assets/images/logo/tenali_logo.png";
import { theme } from "../../../store/customise/customise";
import "../../components/header/index.css";

const { Header } = Layout;

function MenuHeader(props) {
  const dispatch = useDispatch();
  // const theme = useSelector((state) => state.customise.theme);
  const quickMenuItemsList = useSelector(
    (state) => state.system.quickMenuItemsList
  );
  const { setVisible, visible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  function themeClick(index) {
    if (index == 0) {
      document.querySelector("body").classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      dispatch(theme("light"));
    } else {
      document.querySelector("body").classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      dispatch(theme("dark"));
    }
  }

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col>
          <Row align="middle">
            <HeaderSideMenu Side_menu_icon="side_menu_icon" />
            <Col className="hp-d-flex-center  hp-mr-16">
              <Link to="/dashboard">
                <Button
                  type="text"
                  className="hp-mb-16"
                  icon={
                    <div className="hp-position-relative">
                      <HomeOutlined
                        className="hp-text-color-dark-60"
                        style={{ fontSize: "24px", color: "#DA464A" }}
                      />
                    </div>
                  }
                />
              </Link>
            </Col>
          </Row>
        </Col>
        <Col className="hp-logo-item ">
          <img
            src={WhiteLogo}
            style={{
              height: 60,
              width: "100%",
            }}
            alt="Logo"
          />
        </Col>

        <Col>
          <Row align="middle">
            {/* <Col className="hp-d-flex-center hp-mr-4">
              <Switch
                checkedChildren="Light"
                unCheckedChildren="Dark"
                defaultChecked
                onChange={(e) => {
                  themeClick(e ? 0 : 1);
                }}
              />
            </Col> */}

            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        {customise.contentWidth == "full" && (
          <Col span={24}>{headerChildren()}</Col>
        )}

        {customise.contentWidth == "boxed" && (
          <Col xxl={20} xl={22} span={24}>
            {headerChildren()}
          </Col>
        )}
      </Row>
    </Header>
  );
}

export default MenuHeader;
