import { createSlice } from "@reduxjs/toolkit";

import {getStockReport} from "./stockReportActions";

const stockReportSlice = createSlice({
  name: "stockReport",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    filterStatus: false,
    selectedData: {},
    stockReportList: [],
    stockReportParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {

    setStockParams: (state, action) => {
      return {
        ...state,
        stockReportParams: action.payload,
      };
    },
    clearStock: (state, action) => {
      return {
        ...state,
        loading: false,
        stockReportList: [],
        stockReportParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        listCount: 0,
        selectedData: {},
      };
    },
    changeParams: (state, action) => {
      state.stockReportParams = action.payload;
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

  },

  extraReducers: (builder) => {
    builder
      // open stock products
      .addCase(getStockReport.pending, (state, action) => {
        state.status = "getStockReport loading";
        state.loading = true;
      })
      .addCase(getStockReport.fulfilled, (state, action) => {
        state.status = "getStockReport succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.stockReportParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.stockReportList = action.payload.response;
        state.stockReportParams = { ...state.stockReportParams,no_of_pages: noofpages,};
      })
      .addCase(getStockReport.rejected, (state, action) => {
        state.status = "getStockReport failed";
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  setStockParams,
  isDrawerVisible,
  clearStock,
  changeParams,
  InputChangeValue,
} = stockReportSlice.actions;
export default stockReportSlice.reducer;
