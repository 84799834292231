import { createSlice } from "@reduxjs/toolkit";

import { getclosingStockList, getProductList, postClosingStock, getCloseStockById } from "./closingstockActions";

const closingStockSlice = createSlice({
  name: "closingstock",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    StockList: [],
    closingStockParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    productList: [],
    productParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    productCount: 0,
    selectedData: {},
    filterParamsData: {},
  },
  reducers: {
    setClosingStockParams: (state, action) => {
      return {
        ...state,
        closingStockParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    setFilterParams: (state, action) => {
      return {
        ...state,
        filterParamsData: { ...state.filterParamsData, ...action.payload },
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        closingStockParams: {
          ...state.closingStockParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearClosingStock: (state, action) => {
      return {
        ...state,
        loading: true,
        StockList: [],
        closingStockParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        productList: [],
        productParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        selectedData: {},
      };
    },
    changeParams: (state, action) => {
      state.closingStockParams = action.payload;
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    stockInputChangeValue: (state, action) => {
      console.log(action.payload.item.id)
      var selectedStockItem = state.productList.map((item) => {
        if (
          item.id == action.payload.item.id
        ) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        productList: selectedStockItem,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      //post Data
      .addCase(getclosingStockList.pending, (state, action) => {
        state.status = "getclosingStockList loading";
        state.loading = true;
      })
      .addCase(getclosingStockList.fulfilled, (state, action) => {
        state.status = "getclosingStockList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.closingStockParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.StockList = action.payload.response;
        state.closingStockParams = {
          ...state.closingStockParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getclosingStockList.rejected, (state, action) => {
        state.status = "getclosingStockList failed";
        state.loading = false;
        state.error = action.error.message;
      })


      // closing stock products
      .addCase(getProductList.pending, (state, action) => {
        state.status = "getProductList loading";
        state.loading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.status = "getProductList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.productParams.page_size
        );
        state.productCount = action.payload.response.count;
        state.productList = action.payload.response;
        state.productParams = { ...state.productParams, no_of_pages: noofpages, };
      })
      .addCase(getProductList.rejected, (state, action) => {
        state.status = "getProductList failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // post Closing stock
      .addCase(postClosingStock.pending, (state, action) => {
        state.status = "postClosingStock loading";
        state.loading = true;
      })
      .addCase(postClosingStock.fulfilled, (state, action) => {
        state.status = "postClosingStock succeeded";
        state.loading = false;
      })
      .addCase(postClosingStock.rejected, (state, action) => {
        state.status = "postClosingStock failed";
        state.loading = false;
        state.error = action.error.message;
      })


      .addCase(getCloseStockById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCloseStockById.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.selectedData = response;
      })
      .addCase(getCloseStockById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  setClosingStockParams,
  isDrawerVisible,
  clearClosingStock,
  changeParams,
  InputChangeValue,
  stockInputChangeValue,
  setFilterParams,
  FilterInputChangeValue
} = closingStockSlice.actions;

export default closingStockSlice.reducer;
