import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Button,
  Drawer,
  Space,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import {
  isDrawerVisible2,
  changeParams,
  clearClosingStock,
  setFilterParams,
  FilterInputChangeValue,
} from "../../store/sideMenu/dashboard/dashboardSlice";
import {
  getStockDashboard
} from "../../store/sideMenu/dashboard/dashboardActions";
import { getWarehouseList } from "../../store/mini/miniActions";

var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();

function StockFilter() {
  const drawer2 = useSelector((state) => state.dashboard.drawer2);
  const loading = useSelector((state) => state.dashboard.loading);
  const pageParams = useSelector((state) => state.dashboard.pageParams);
  const warehouseParams = useSelector((state) => state.mini.warehouseParams);
  const warehoueList = useSelector((state) => state.mini.warehoueList);


  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;



  function onFinish() {
    dispatch(changeParams({ ...pageParams }));
    dispatch(getStockDashboard({
      ...pageParams,
      //  warehouse: pageParams?.warehouseParams?.id ? pageParams?.warehouseParams?.id : warehoueList?.find(e => e)?.id,
       warehouse: pageParams?.warehouse ? pageParams?.warehouse : warehoueList?.find(e => e)?.id,
      page: 1,
      search: "",
      page_size: 10,
    }))
    dispatch(isDrawerVisible2(false));
  }


  function clearFilter() {
    dispatch(clearClosingStock());
    dispatch(getStockDashboard({
      ...pageParams,
      warehouse: warehoueList?.find(e => e)?.id,
      page: 1,
      search: "",
      page_size: 10,
    }))
    form.resetFields();
  }

  

  const handleChangeFn = (data) => {
    dispatch(
      changeParams({
        ...pageParams,
        [data.key]: data.value,
      })
    );
  };

  useEffect(() => {
    handleChangeFn({
      key: "warehouse",
      value: warehoueList?.find(e => e)?.id
    })
  }, [warehoueList])

  useEffect(() => {
    form.setFieldsValue({
      // warehouse: pageParams?.warehouse ? pageParams.warehouse : warehoueList[0]?.name,
      warehouse: pageParams?.warehouse,
    });
  }, [pageParams]);

 
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Drawer
          title="Filters"
          loading={loading}
          width={420}
          className="hp-drawer-mobile"
          onClose={() => {
            dispatch(isDrawerVisible2(false));
          }}
          placement="right"
          open={drawer2}
          bodyStyle={{
            paddingBottom: 10,
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFieldsChange={handleFieldsChange}
          >
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  label="Warehouse"
                  name="warehouse"
                  className="hp-mb-0"
                >
                  <Select
                    showSearch
                    placeholder="Select Warehouse"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    optionFilterProp="warehouse"
                    maxTagCount="responsive"
                    onSearch={(value) => {
                      dispatch(
                        getWarehouseList({
                          page: 1,
                          search: value,
                          page_size: 10,
                        })
                      );
                    }}
                    onFocus={() => {
                      dispatch(
                        getWarehouseList({
                          page: 1,
                          search: "",
                          page_size: 10,
                        })
                      );
                    }}
                    defaultActiveFirstOption={true}
                    showArrow={true}
                    filterOption={false}
                    onChange={(value) => {
                      handleChangeFn({
                        key: "warehouse",
                        value: value,
                      });
                    }}
                    defaultValue={warehoueList[0]?.name}
                    value={pageParams.warehouse}
                    onPopupScroll={() => {
                      if (warehouseParams.page < warehouseParams.no_of_pages) {
                        dispatch(
                          getWarehouseList({
                            page: warehouseParams?.page + 1,
                            search: "",
                            page_size: 10,
                          })
                        );
                      }
                    }}
                  >
                    {warehoueList?.map((warehouse, index) => {
                      return (
                        <Option key={index} value={warehouse.id}>
                          {warehouse?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col span={24} xs={12} sm={12} md={12}>
                <Button
                  block
                  type="secondary"
                  className="hp-submit-btn hp-mt-10"
                  onClick={() => {
                    clearFilter();
                  }}
                  htmlType="button"
                >
                  Clear
                </Button>
              </Col>
              <Col span={24} xs={12} sm={12} md={12}>
                <Button
                  block
                  type="primary"
                  className="hp-submit-btn hp-mt-10"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Col>
    </Row>
  );
}

export default StockFilter;
