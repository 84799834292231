import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	getList,
	getParamsList,
	postAdd,
	postEdit,
	postFormData,
	postDelete,
} from "../../../helpers/Helper";



export const getStockDashboard = createAsyncThunk(
	"/getStockDashboard",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					// (data[k] !== "" && k !== null && k !== "no_of_pages")
					if (data[k] !== "" && k !== "no_of_pages") {
						if (k === "date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			const response = await getParamsList(
				"/stockmanagement/dashboard_counts/", params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);


// export const getWeakDashboard = createAsyncThunk(
// 	"/getWeakDashboard",
// 	async () => {
// 	  const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
// 	  const endDate = moment().format('YYYY-MM-DD');
// 	  const params = { startDate, endDate };
  
// 	  try {
// 		const response = await getParamsList(
// 		  "/stockmanagement/dashboard_week_counts/", params
// 		);
// 		if (response) {
// 		  return { response };
// 		} else {
// 		  throw new Error(response);
// 		}
// 	  } catch (error) {
// 		return error.message;
// 	  }
// 	}
//   );


export const getWeakDashboard = createAsyncThunk(
  "/getWeakDashboard",
  async (payload) => {
	var data = payload;
    var params = {};
    for (const k in data) {
      if (Object.hasOwnProperty.call(data, k)) {
        if (k === "currentSort" || k === "sortOrder") {
          params.ordering = data.sortOrder + data.currentSort;
        } else {
          // (data[k] !== "" && k !== null && k !== "no_of_pages")
          if (data[k] !== "" && k !== "no_of_pages") {
            if (k === "start_date") {
              params[k] = moment(data[k]).format("YYYY-MM-DD");
            } else if (k === "end_date") {
              params[k] = moment(data[k]).format("YYYY-MM-DD");
            } else {
              params[k] = data[k];
            }
          }
        }
      }
    }
    try {
      const response = await getParamsList(
        "/stockmanagement/dashboard_month_counts/",
        params
      );
      if (response) {
        return  response ;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return error.message;
    }
  }
);


// export const getTodayDashboard = createAsyncThunk(
// 	"/getTodayDashboard",
// 	async (payload) => {
// 	  var data = payload;
// 	  var params = {};
// 	  for (const k in data) {
// 		if (Object.hasOwnProperty.call(data, k)) {
// 		  if (k === "currentSort" || k === "sortOrder") {
// 			params.ordering = data.sortOrder + data.currentSort;
// 		  } else {
// 			// (data[k] !== "" && k !== null && k !== "no_of_pages")
// 			if (data[k] !== "" && k !== "no_of_pages") {
// 			  if (k === "date") {
// 				params[k] = moment(data[k]).format("YYYY-MM-DD");
// 			  } else {
// 				params[k] = data[k];
// 			  }
// 			}
// 		  }
// 		}
// 	  }
// 	  try {
// 		const response = await getParamsList(
// 		  "/stockmanagement/dashboard_week_counts/",
// 		  params
// 		);
// 		if (response) {
// 		  return { response };
// 		} else {
// 		  throw new Error(response);
// 		}
// 	  } catch (error) {
// 		return error.message;
// 	  }
// 	}
//   );
  



// old api's
export const getclosingStockByDate = createAsyncThunk(
	"/getclosingStockByDate",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					// (data[k] !== "" && k !== null && k !== "no_of_pages")
					if (data[k] !== "" && k !== "no_of_pages") {
						if (k === "date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			// const response = await getParamsList(
			//   `/Stockmanagement/closingstockbydate/{warehouse}/{date}/`,
			//   params
			// );
			const response = await getParamsList(
				"/Stockmanagement/cdashboardbydate/" +
					data.warehouse +
					"/" +
					data.date +
					"/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getOpeningStockByDate = createAsyncThunk(
	"/getOpeningStockByDate",
	async (payload) => {
		var data = payload;
		var params = {};
		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					// (data[k] !== "" && k !== null && k !== "no_of_pages")
					if (data[k] !== "" && k !== "no_of_pages") {
						if (k === "date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			// const response = await getParamsList(
			//   `/Stockmanagement/closingstockbydate/{warehouse}/{date}/`,
			//   params
			// );
			const response = await getParamsList(
				"/Stockmanagement/odashboardbydate/" +
					payload.warehouse +
					"/" +
					payload.date +
					"/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getProductList = createAsyncThunk(
	"/getProductList",
	async (payload) => {
		const { params } = payload;
		try {
			const response = await getParamsList(
				"/Stockmanagement/product/",
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);





